@import "../../App/Styles/Variables.scss";

.help-my {
  @media (min-width: 1500px) {
    margin: 0px 4px 0px 10px;
  }

  @media (max-width: 1500px) {
    margin: 0px 9px 0px 10px;
  }

  @media (max-width: 1200px) {
    margin: 30px 10px 30px 5px;
  }

  @media (max-width: 768px) {
    margin: 10px 0px 0px 0px;
  }

  .beneficiaries-scss {
    .background-card {
      &.margin {
        margin-top: 10px;

        @media (max-width: 1200px) and (min-width: 768px) {
          width: 100%;
          margin-left: 0px;
        }

        @media (max-width: 768px) {
          padding-top: 15px;
        }
      }

      .verify-checkbox-container {
        margin-top: 0;
      }
    }

    .card-heading-bfs {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 16px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $standard-black;

      @media (max-width: 500px) {
        font-size: 15px;
        line-height: 25px;
        margin-left: 10px;
      }
    }

    .beneficiary-tab-container {
      .tabs-header {
        text-align: center;
        font-family: "NotoSans-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-left: 0px;
        padding-right: 0px;

        @media (max-width: 768px) {
          display: none;
        }

        .nav-link {
          color: $standard-black;
          border: none;

          &:focus-visible {
            box-shadow: none;
          }

          &.active {
            font-family: "NotoSans-SemiBold";
            font-weight: 600;
            border-top: none;
            border-left: none;
            border-right: none;
            border-bottom: 4px solid $azureBlue;
          }

          &.hover {
            border: none;
          }
        }

        .beneficiary-tab {

          .alert-error,
          .alert-approved {
            height: 20px;
            width: 20px;
          }

          .alert-error,
          .alert-approved,
          .alert-warning {
            margin: 3px;
          }
        }
      }
    }

    .benefit-card-bfs {
      background: $white;
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      border: 4px $standard-black;
    }

    .benefit-card-heading-bfs {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 31px;
      color: #424757;

      @media (max-width: 1200px) {
        font-size: 16px;
      }

      @media (max-width: 500px) {
        font-size: 18px;
      }
    }

    .line-bfs {
      border: 1px solid $greyLine;
      width: 100%;
    }

    .benefit-card-information-bfs {
      font-family: "NotoSans-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      color: $standard-black;
      margin-top: 8px;

      @media (max-width: 1200px) {
        font-size: 12px;
      }

      @media (max-width: 500px) {
        font-size: 14px;
      }
    }

    .neo-card {
      &.card {
        &.benefit-card-bfs {
          box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
          border-left: 7px solid $azureBlue;
          margin-top: 25px;

          @media (max-width: 500px) {
            margin-top: 20px;
          }

          .right-arrow {
            height: 24px;
            width: 24px;
            background-color: transparent;
            appearance: none;
            background-image: url("../../App/assets/img/down-right-black.png");
            background-repeat: no-repeat;
            border: none;
            float: right;
            margin-top: 7px;
          }

          .card-header {
            background: none;
            border-bottom: none;
            display: block;
            padding-right: 8px;

            @media (max-width: 1200px) {
              padding-right: 2px;
            }

            @media (max-width: 500px) {
              padding-right: 6px;
            }
          }

          .card-body {
            padding: 0px 16px 16px 16px;

            @media (max-width: 1200px) {
              padding-right: 6px;
            }
          }
        }
      }
    }

    .yellow-status-text {
      color: #ffad30;
    }

    .green-status-text {
      color: $darkerGreen;
    }

    .yellow-warning-img {
      width: 16.54px;
      height: 13.59px;
      float: right;
      margin-top: 7px;
      margin-right: 0px;

      @media (max-width: 1200px) {
        margin-right: 4px;
      }

      @media (max-width: 500px) {
        margin-right: 8px;
      }

      &.approved {
        width: 20px;
        height: 20px;
      }
    }

    .tabs-dropdown-beneficiaries {
      display: none;

      @media (max-width: 768px) {
        display: contents;
        font-family: "NotoSans-Medium";
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: $black;
      }

      .dropdown-header-beneficiaries {
        display: contents;
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $black;
        margin: 4% 0px 0px 1px;
      }
    }

    .tabs-dropdown-beneficiaries {
      .react-select-bs__control {
        background:
          url("../../App/assets/img/drop-down-arrow.png") no-repeat 97% 50%,
          $shadowGrey;

        &.react-select-bs__control--menu-is-open {
          background:
            url("../../App/assets/img/drop-up-arrow.png") no-repeat 97% 50%,
            $shadowGrey;
        }

        .react-select-bs__indicators {
          display: none;
        }
      }
    }
  }
}

.or-text {
  font-family: "NotoSans-Regular";
  font-weight: 400;
  line-height: 19px;
  font-size: 14px;
  color: $standard-black;
  text-decoration-line: none;
  margin: 0 18px 0 14px;

  @media (max-width: 768px) {
    line-height: 30px;
  }
}

.modal-container {
  .modal-dialog {
    &.modal-lg {
      &.trusted-person-beneficiaries-modal {
        .modal-content {
          .modal-header {
            padding-bottom: 0px;
            padding-left: 14px;

            @media (max-width: 768px) {
              padding-left: 9px;
            }

            .modal-title {
              line-height: 10px;
            }
          }

          .modal-body {
            @media (max-width: 768px) {
              line-height: 23px;
            }
          }

          .modal-footer {
            @media (max-width: 768px) {
              margin-top: 5px;
            }
          }
        }
      }

      &.know-beneficiaries-modal {
        .modal-content {
          max-width: 100%;
          width: 1000px;
          align-items: normal;

          @media (max-width: 768px) {
            width: 100%;
            max-width: 500px;
            min-width: 320px;
          }

          .modal-header {
            margin-left: 15px;

            @media (max-width: 768px) {
              margin-left: 0px;
            }
          }

          .modal-body {
            text-align: left;
            padding: 10px 30px 0px 30px;

            @media (max-width: 768px) {
              padding: 10px 10px 0px 10px;
            }

            .align-sub-title {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              color: $standard-black;

              @media (max-width: 768px) {
                display: flex;
                padding-bottom: 10px;
              }
            }
          }

          .modal-footer {
            width: 100%;
            display: flex;
            border: none;
            justify-content: flex-start;
            flex-wrap: nowrap;
            flex-direction: row-reverse;
            padding-right: 25px;

            @media (max-width: 768px) {
              flex-direction: column;
              padding-right: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
            }

            .btn {
              width: 220px;

              &.btn-outline-danger {
                width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

/////////////////////////////////beneficiaries-approved-death-benefits////////////////////////////////////////////

.beneficiaries-approved-death-benefits {
  .adding-margin-to-the-sides {
    margin: 0px 10px 0px 10px;

    @media (max-width: 768px) {
      margin: 0px 5px 0px 5px;
    }

    &.adding-margin-bottom {
      margin: 0px 10px 80px 10px;
    }
  }

  .benefit-info-link {
    color: $azureBlue;
    font-family: "NotoSans-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }

  .alert-height {
    height: 380;
  }

  .heading-above-line {
    margin-bottom: 10px;
  }

  .card-header {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;

    @media (max-width: 768px) {
      font-size: 15px;
    }
  }

  .line-badb {
    border-bottom: 1px solid $greyLine;
    width: 100%;
    margin: 10px 0 14px 0;

    &.funeral-beneficiaries-line-padding {
      @media (max-width: 768px) {
        margin: 25px 0 10px 0;
      }
    }
  }

  .blue-box {
    background: #deecff;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    &.align-content-flex-start {
      justify-content: flex-start;
      align-items: center;
    }

    .blue-warning-image {
      width: 24px;
      height: 24px;
    }

    .blue-box-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: 0.5px;
      color: #00070f;
      margin-left: 5px;
    }
  }

  .details-outstanding {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    float: right;
    padding-top: 0px;

    @media (max-width: 768px) {
      float: none;
      margin: 20px auto;
    }

    &.yellow-status-text {
      color: #ffad30;
    }

    &.green-status-text {
      color: $darkerGreen;
    }
  }

  .total-share-value {
    width: 100%;
    min-height: 48px;
    height: max-content;
    background: $white;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid $amber;
    background: $white;

    @media (max-width: 768px) {
      margin-bottom: 10px;
      justify-content: center;
    }

    &.total-share-value-border {
      border: 1px solid $darkerGreen;
    }

    .total-share-value-text {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      color: $standard-black;

      .yellow-total-percentage {
        color: $amber;
      }

      .green-total-percentage {
        color: $darkerGreen;
      }
    }
  }

  .row {
    &.table-heading-badb {
      margin-left: 3px;
    }
  }

  .card-heading-text {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: $standard-black;
  }

  .approved-benefit-card-items {
    height: 74px;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    border: none;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: $standard-black;
    margin-top: 15px;
    padding-top: 5px;

    @media (max-width: 768px) {
      min-height: 162px;
      height: fit-content;
      font-size: 12px;
      line-height: 20px;
      color: $standard-black;

      &.funeral-benefit-height {
        padding-top: 0px;
      }
    }
  }

  .col-3 {
    &.card-heading-text {
      &.funeral-full-name {
        width: 28%;
      }
    }

    &.padding-beneficiary-number {
      padding: 0px;
      width: 28%;
    }
  }

  .table-card-name {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: $standard-black;

    .beneficiary-number {
      font-family: "NotoSans-regular";
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .col-5 {
    &.card-text-phone-screen {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $standard-black;
      padding-top: 10px;

      &.share {
        padding-top: 20px;
      }
    }
  }

  .col-7 {
    &.padding-phone {
      padding-top: 6px;

      &.table-card-name {
        font-family: "NotoSans-SemiBold";
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: $standard-black;

        .beneficiary-number {
          font-family: "NotoSans-regular";
          font-weight: 400;
          text-decoration: underline;
          font-size: 12px;
        }
      }

      &.share {
        padding: 16px 0 8px 10px;
      }
    }
  }

  .icon-cu-beneficiaries-table {
    width: 48px;
    height: 48px;
    float: left;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $azureBlue;
    color: $white;
    font-size: 12px;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    border-radius: 50%;
    margin-right: 15px;
    margin-left: 7px;
  }

  .share-input-field {
    .form-control {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $standard-black;
      background: $shadowGrey;
      border: 1px solid #8b92a7;
      border-radius: 4px;
      height: 48px;
      width: 97px;
      text-align: left;

      @media (max-width: 768px) {
        height: 25px;
        width: 53px;
        font-size: 12px;
        padding-left: 5px;
        width: inherit;
      }
    }
  }

  .increase-share-arrow {
    width: 24px;
    height: 24px;
  }

  .card-items-padding-buttons {
    padding-top: 8px;
  }

  .btn-secondary {
    &.fake-button {

      cursor: default;

      &:active {
        background-color: $white;
      }
    }
  }

  .col-2 {
    &.card-items-padding {
      padding-top: 8px;

      &.id-number {
        margin-left: 3px;
      }

      &.relationship {
        margin-left: 5px;

        @media (max-width: 1200px) {
          margin-left: 10px;
        }
      }

      &.share {
        padding-top: 0px;

        @media (max-width: 1200px) {
          padding-left: 30px;
        }
      }

      &.delete {
        margin: 0 0 0 auto;
        width: 10%;

        @media (max-width: 1260px) {
          margin-left: 0px;
          width: 12%;
        }
      }
    }
  }

  .col-4 {
    &.card-items-padding {
      padding-top: 8px;

      &.id-number {
        margin-left: 3px;
      }

      &.relationship {
        margin-left: 5px;
        width: 29%;

        @media (max-width: 1200px) {
          margin-left: 10px;
        }
      }
    }
  }

  .col-1 {
    &.card-items-padding {
      margin: 0 0 0 auto;
    }
  }

  .link-format {
    text-decoration: none;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #0033aa;
  }

  .neo-card {
    &.card {
      &.approved-benefit-card-items {
        .card-body {
          padding-top: 10px;
        }
      }
    }
  }

  .edit-image-size {
    width: 24px;
    height: 24px;
    padding: 0px;

    &.padding {
      margin-right: 5px;
    }
  }

  .blue-block {
    border: 1px dashed $azureBlue;
    height: 74px;
    width: 100%;
    background: #deecff;
    border-radius: 4px;
    text-align: center;
    margin: 20px 0 20px 0;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 25px;

    @media (max-width: 768px) {
      height: 131px;
      text-align: center;
    }

    .blue-block-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #0033aa;
      margin-right: 7px;
    }

    .or-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      line-height: 19px;
      font-size: 14px;
      color: $standard-black;
      text-decoration-line: none;
      margin: 0 18px 0 14px;

      @media (max-width: 768px) {
        line-height: 30px;
      }
    }

    .add-text {
      font-family: "NotoSans-Medium";
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1.23px;
      color: $azureBlue;
      text-decoration-line: none;
    }

    &.blue-block-for-trusted-person {
      height: 75px;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-top: 0px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
    }
  }

  .grey-no-benificiaries-box {
    width: 100%;
    height: 380px;
    background: $main-background;
    border-radius: 6px;
    padding: 50px 4%;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      height: 470px;
    }

    .grey-no-benificiaries-box-image {
      width: 40px;
      height: 40px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;
    }

    .grey-no-benificiaries-box-text {
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 16px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $lighterGrey;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .greyed-out-block {
    border: 1px dashed #767676;
    height: 74px;
    width: 100%;
    background: #f0f1f4;
    border-radius: 4px;
    text-align: center;
    margin: 20px 0 20px 0;
    padding-top: 25px;
    pointer-events: none;

    @media (max-width: 768px) {
      height: 131px;
      text-align: center;
    }

    .blue-block-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: $lighterGrey;
      margin-right: 7px;
    }



    .add-text {
      font-family: "NotoSans-Medium";
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1.23px;
      color: $lighterGrey;
      text-decoration-line: none;
    }
  }

  .nominated-form-background {
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    font-size: 12px;
    line-height: 25px;
    color: $standard-black;
    padding: 15px;
    margin: 0px 10px 20px 10px;

    .upload-card {
      padding: 8px;

      &.card {
        background: $white;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      }
    }

    .display-alert-employer-beneficiary {
      &.alert {
        background: url(../../App/assets/img/error.png) no-repeat 1% 45%;
        padding-left: 50px;

        h6 {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: $standard-black;
          margin-bottom: 0px;
        }
      }

      &.alert-primary {
        margin-top: 10px;
        border: none;
        border-radius: 4px;
        background-color: #f8e6c3;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
      }
    }

    .nominated-form-heading {
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 16px;

      &.margin {
        margin: 10px 0px;
      }
    }

    .nominated-form-sub-heading {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 14px;
    }

    .file-drop-area {
      background: #deecff;
      border-radius: 4px;
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      flex-direction: row;
      flex-wrap: wrap;

      &.neo-file-drop {
        border: 2px dashed $azureBlue;
      }

      .file-upload-label {
        font-family: "NotoSans-Medium";
        font-weight: 500;
        font-size: 14px;
        color: $azureBlue;
        margin-left: 10px;
      }
    }

    .browse-button {
      &.btn {
        border: none;
        background: none;
        text-decoration: underline;
        cursor: pointer;
        color: $azureBlue;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.428571px;
        padding-left: 0.3%;
        width: auto;

        &.btn:hover,
        &.btn:first-child:hover {
          border: none;
          background: none;
          text-decoration: underline;
          cursor: pointer;
          color: $azureBlue;
          font-family: "NotoSans-Medium";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.428571px;
          padding-left: 0.3%;
          width: auto;
          font-size: 14px;
        }
      }
    }

    .nominated-grey-form-background {
      padding: 15px;
      width: max-content;
      border-radius: 4px;
      background: #eef5ff;

      .nominated-grey-form-heading {
        font-family: "NotoSans-Regular";
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: $azureBlue;
        letter-spacing: 0.429px;

        .float-right {
          float: right;
          margin-left: 20px;

          img {
            margin-left: 3px;
          }

          &.hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .end-of-page {
    margin-top: 20px;
    padding-left: 25px;
    padding-right: 25px;

    @media (max-width: 768px) {
      margin-top: 0;
    }
  }

  .line-break {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .show-on-web-screen {
    display: contents;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .show-on-phone-screen {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .alert-text-badb {
    &.alert-del {
      width: 60%;
    }

    &.alert {
      &.alert-success {
        background: #e3fcef;
        border-radius: 4px;
        border: none;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);

        button {
          display: none;
        }
      }

      .alert-text-headging-badb {
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        color: $standard-black;
        margin-bottom: 5px;

        @media (max-width: 768px) {
          font-size: 15px;
        }

        &.sub-heading {
          font-size: 12px;
          line-height: 18px;
          color: #424757;
        }
      }
    }

    &.alert-warning {
      background: #fff7e5;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      border: none;

      button {
        display: none;
      }
    }
  }

  .col-1 {
    &.alert-image-padding {
      margin-top: 8px;
      width: 35px;
    }
  }

  .col-1 {
    &.width-success {
      width: 12%;
    }
  }

  .row {
    &.alert-margin {
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
    }
  }

  .last-updated-by {
    color: $standard-black;
    font-family: "NotoSans-Regular";
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
    margin-bottom: 20px;

    &.add-margin-top {
      margin-top: 25px;
    }

    @media (max-width: 768px) {
      justify-content: center;
      margin-top: 30px;
    }

    .employer {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
    }
  }

  .align-changes-text {
    font-family: "NotoSans-Regular";
    font-size: 14px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0px;
    text-align: right;
    color: #FFAD30;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }

  .switch-beneficiaries {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      justify-content: center;
    }

    &.adding-margin-top {
      margin-top: 20px;
    }

    .switch-beneficiaries-button {
      color: $component-header-background;
      font-family: "NotoSans-Medium";
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      background: $white;
      box-shadow: 0px 1px 4px 0px rgba(139, 146, 167, 0.5);
      border: none;

      &.btn {
        &.btn-primary {
          --bs-btn-active-bg: $white;
          --bs-btn-focus-bg: $white;
        }
      }
    }
  }
}

/////////////////////////////////beneficiaries-all-known///////////////////////////////////////////////////////

.beneficiaries-all-known {
  .background-card {
    &.bak {
      margin-top: 0px;
      min-height: 150px;
      padding: 0px;
    }
  }

  .card-header-bak {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    padding: 15px 20px;
  }

  .line-bak {
    border-bottom: 1px solid $greyLine;
    width: 100%;
    margin: 10px 0 42px 0;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }

    &.bottom-line {
      margin: 60px 0 10px 0;

      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
  }

  .table-header-bak {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: $standard-black;
    padding-left: 30px;
  }

  .col-2 {
    &.align-checkbox-on-trusted-persons-card {
      display: flex;
    }
  }

  .checkbox-table-bak {
    float: right;
    padding-top: 2px;
    margin-left: 0px;
    background-color: $white;
    border: none;
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #0033aa;

    @media (max-width: 768px) {
      float: right;

      &.btn {
        --bs-btn-hover-bg: none;
        --bs-btn-active-bg: none;
      }
    }

    &.sub-select {
      padding-top: 10px;
    }

    &.header {
      margin: 0 0 15px auto;
      float: right;

      .col-8 {
        &.col-form-label {
          padding: 7px 0 0 0;
          line-height: 0.5;

          @media (max-width: 768px) {
            line-height: 0;
          }
        }
      }

      &.head {
        padding: 0 10px 2px 0;
        margin-bottom: 2px;
      }
    }

    &.btn:disabled {
      background-color: $white;
    }
  }

  .checked-box {
    margin-right: 15px;
  }

  .table-header-check-box-bak {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    color: #0033aa;
    text-align: right;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 0px;
      margin-bottom: 5px;
    }

    &.heading {
      margin-right: 5px;
      line-height: 32px;

      @media (max-width: 768px) {
        float: right;
        line-height: 28px;
      }
    }
  }

  .profile-image-bak {
    height: 48px;
    padding: 0px;
    margin: 0px 5px;
  }

  .table-card-bak {
    height: 74px;
    width: 100%;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    margin-top: 10px;
    border: none;

    @media (max-width: 768px) {
      min-height: 105px;
      height: fit-content;
    }

    &.padding-right {
      @media (max-width: 400px) {
        padding-right: 0px;
      }
    }

    .checkbox-table-bak {
      &.btn:disabled {
        background-color: $white;
      }
    }
  }

  .table-text-bak {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: $standard-black;
    padding-left: 20px;

    &.name {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      line-height: 19px;
      padding-left: 10px;
    }

    &.relationship {
      padding-left: 30px;
    }

    &.padding {
      padding-top: 8px;
    }
  }

  .table-heading-text-phone-bat {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $standard-black;
    padding-top: 4px;
  }

  .table-text-phone-bat {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    color: $standard-black;
    line-height: 16px;
    padding-top: 4px;
  }

  .known-beneficiaries-table {
    @media (max-width: 768px) {
      display: none;
    }

    &.table {
      border-top: $white;
      border-collapse: inherit;
      border-spacing: 0px 15px;

      thead {
        color: $standard-black;
        font-family: "NotoSans-SemiBold";
        font-size: 14px;
        font-weight: 600;
        line-height: 31px;

        th {
          border: none;
          padding: 7px;
          vertical-align: middle;
          background: none;

          .column-sort-icon {
            right: auto;
            margin: 7px 0px 0px 10px;
          }
        }
      }

      tbody {
        border-style: unset;

        tr {
          height: 74px;
          fill: $white;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          margin-bottom: 10px;

          td {
            border: none;

            .checkbox-table-bak {
              &.btn:disabled {
                background-color: $white;
              }

              &.sub-select {
                &.btn {
                  &.btn-primary {
                    --bs-btn-hover-color: none;
                    --bs-btn-hover-bg: none;
                    --bs-btn-active-color: none;
                    --bs-btn-active-bg: none;
                    --bs-btn-active-border-color: none;

                    img {
                      padding-right: 5px;
                    }
                  }
                }
              }
            }

            .icon-cu-beneficiaries {
              width: 32px;
              height: 32px;
              float: left;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: $azureBlue;
              color: $white;
              font-size: 12px;
              font-family: "NotoSans-Regular";
              font-weight: 400;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }

  .empty-beneficiaries {
    text-align: center;
    min-height: 200px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;

    .empty-beneficiaries-text {
      font-family: "NotoSans-Regular";
      font-size: 14px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: center;
      color: $lighterGrey;
      margin-top: 20px;
    }
  }

  .show-on-web-bat {
    display: contents;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .show-on-phone-bat {
    display: none;

    @media (max-width: 768px) {
      display: contents;
    }
  }

  .row {
    &.id-padding {
      padding-top: 4px;
    }
  }

  .col-1 {
    &.width-text {
      @media (max-width: 768px) {
        padding-right: 0px;
        width: 14%;
      }

      @media (max-width: 400px) {
        padding: 0;
        width: 5%;
      }

      .col-8 {
        &.col-form-label {
          @media (max-width: 768px) {
            width: 10%;
          }

          @media (max-width: 400px) {
            padding-left: 0px;
          }
        }
      }
    }
  }

  .col-md-1 {
    &.select {
      padding-left: 0px;
    }

    &.padding-select-all {
      padding: 0;
    }
  }

  .col-2 {
    &.width-text {
      @media (max-width: 400px) {
        padding-right: 0px;
      }
    }
  }

  .col-4 {
    &.width-bootstrap {
      @media (max-width: 768px) {
        width: 29%;
      }

      @media (max-width: 400px) {
        width: 35%;
        padding-right: 0px;
      }
    }

    &.width-text {
      @media (max-width: 768px) {
        width: 40%;
      }

      @media (max-width: 400px) {
        width: 40%;
        padding-right: 0px;
      }

      &.table-padding-right {
        @media (max-width: 400px) {
          padding-left: 0px;
        }
      }
    }
  }

  .col-7 {
    &.table-padding-right {
      @media (max-width: 400px) {
        padding-left: 0px;
      }
    }
  }

  .check-box-image-size {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  .checkbox-disabled {
    color: gray;
    pointer-events: none;
  }
}

/////////////////////////////// beneficiaries edit known //////////////////////////////////////////////////////////
.beneficiaries-edit-known {
  padding-left: 10px;

  .input-group-text {
    height: 47px;
    font-weight: 100;
  }

  .share-alocation {
    width: 97%;
  }

  .pv-width {
    width: 97%;
  }

  .input-group {
    width: 91%;
    margin-left: 10px;
  }

  .province-state-label {
    color: $standard-black;
    padding-left: 15px;
  }

  .share-input {
    width: 94%;
  }

  .divider1 {
    padding-left: 0px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 10px;
    width: 98.6%;
    height: 0px;
    border: 1px solid $greyLine;

    @media (max-width: 768px) {
      width: 94.6%;
    }

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 98%;
    }
  }

  .horizontal-line {
    color: #00070f;
  }
}

.benficiary-edit-modal {
  .modal-header {
    color: #00164e;
    border: none;
    padding-bottom: 0px;
  }

  button.close {
    background: none;
    border: none;

    span {
      font-size: 31px;
      float: right;
      color: $azureBlue;
    }
  }

  .ben-align-back-btn {
    padding-left: 14px;

    @media (max-width: 428px) {
      text-align: center;
      justify-content: center;
    }

    @media (max-width: 360px) {
      text-align: center;
      justify-content: center;
    }
  }

  .ben-save-portfolio-link {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $azureBlue;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 360px) and (max-width: 428px) {
      color: $azureBlue;
      display: block;
      text-decoration: none;
      font-size: 12px;
      text-align: center;
    }
  }

  .form-control {
    border: none;
  }

  .modal-content {
    border: none;

    .modal-heading {
      width: 300px;
      height: 26px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.142857px;
      color: $standard-black;
      margin: auto;
    }

    .model-body {
      padding: 0;
    }

    .modal-text {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: $standard-black;
      padding-top: 3%;
    }

    .modal-footer {
      border: none;
      justify-content: center;
      border-radius: 4px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $white;

      .btn-success {
        width: 100%;
        color: $white;
        --bs-gradient: none;
        background-color: $azureBlue;
        border-color: $azureBlue;
        padding: 3%;
      }

      .btn-secondary {
        display: none;
      }
    }
  }
}

.beneficiaries-modal {
  .modal-header {
    color: $component-header-background;
    border: none;
    padding-bottom: 0px;
  }

  button.close {
    display: none;
  }

  .modal-content {
    border: none;

    .modal-heading {
      width: 300px;
      height: 26px;
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.142857px;
      color: $standard-black;
      margin: 40px auto 20px auto;
    }

    .modal-body {
      padding: 0;
    }

    .modal-text {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: $standard-black;
      padding: 3% 30px 2% 30px;
    }

    .modal-footer {
      border: none;
      justify-content: center;
      border-radius: 4px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: $white;

      .btn-success {
        width: 100%;
        color: $white;
        --bs-gradient: none;
        background-color: $azureBlue;
        border-color: $azureBlue;
        padding: 3%;
      }

      .btn-secondary {
        display: none;
      }
    }

    .grey-block-message {
      height: 56px;
      background: $gray;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .checkbox-table-bak {
        &.btn:disabled {
          background-color: $white;
        }

        &.sub-select {
          &.btn {
            &.btn-secondary {
              margin-top: 2%;
              border: none;
              --bs-btn-color: none;
              --bs-btn-bg: none;
              --bs-btn-border-color: none;
              --bs-btn-hover-color: none;
              --bs-btn-hover-bg: none;
              --bs-btn-hover-border-color: none;
              --bs-btn-active-color: none;
              --bs-btn-active-bg: none;
              --bs-btn-active-border-color: none;

              img {
                margin-bottom: 2px;
                padding-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.card-heading-products {
  font-family: "NotoSans-SemiBold";
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  letter-spacing: 0.2px;
  color: $black;
  margin: 10px 0px 0px 0px;
  padding-top: 20px;
  padding-left: 20px;

  @media (max-width: 500px) {
    font-weight: 500;
    padding-top: 10px;
    margin-bottom: 15px;
  }

  @media (max-width: 400px) {
    font-weight: 500;
    margin-bottom: 0px;
  }
}

.background-card {
  &.bak {
    margin-top: 0px;
    min-height: 150px;
    padding-bottom: 5px;
  }
}

.fgInput {
  width: 100%;
}

.btn-pad {
  padding-right: 100px;
}

/////////////////////////////// benefiriaries edit known //////////////////////////////////////////////////////////

//////////////////////////////////beneficiaries modal ////////////////////////////////////////////////////////////////
.beneficiaries-css {
  .modal-content {
    .modal-header {
      border-bottom: none;
    }

    .modal-footer {
      border-top: none;
    }
  }

  &.modal-risk-benefits-header {
    .modal-content {
      width: 542px;
      height: 355px;
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 5px;

      @media (max-width: 500px) {
        width: 320px;
        height: 401px;
      }

      .modal-body {
        .title-modal-risk-benfits {
          font-family: "NotoSans-Regular";
          text-align: center;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: 0.142857px;
          color: $standard-black;
          margin: 15px auto 25px auto;

          @media (max-width: 500px) {
            margin: 0px auto 20px auto;
          }
        }
      }
    }
  }

  .modal-risk-benefits-description {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    word-break: "normal";
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.133333px;
    color: $black;
    padding: 0% 8% 0px 8%;

    @media (max-width: 500px) {
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.133333px;
      margin-bottom: 8%;
    }
  }

  .modal-risk-benefits-button {
    width: 238.35px;
    height: 40px;
    margin: 40px 0px 0px 3px;

    @media (max-width: 500px) {
      margin: 10px 0px 0px 8%;
    }

    &.modal-risk-benefits-ok-text-button {
      &.btn {
        &.btn-outline-primary {
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 1.23px;
          color: $azureBlue;
          background: $white;
          border: 2px solid $azureBlue;
          border-radius: 4px;
        }
      }
    }

    &.modal-risk-benefits-red-text-button {
      &.btn {
        &.btn-primary {
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          color: $white;
          border: none;
          background-color: #f46363;
          border-radius: 4px;
          background-image: none;
        }
      }
    }
  }

  .beneficiaries-css,
  .modal {
    .modal-container {
      margin-top: 10%;

      @media (max-width: 500px) {
        margin: 18% 0px 0px 12.5%;
      }

      @media (max-width: 400px) {
        margin-left: 3.5%;
      }
    }
  }
}

////////////////////////////////// end of beneficiaries modal ////////////////////////////////////////////////////////////////

///////////////////////////////add-new-beneficiaries////////////////////

.add-new-beneficiaries {
  .card-heading-add {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
    padding: 20px;

    @media (max-width: 768px) {
      font-size: 15px;
      padding: 10px 20px;
    }
  }

  .row {
    &.remove-line-margin {
      margin: auto;
    }
  }

  .center-form {
    @media (max-width: 768px) {
      margin: 0% 0%;
    }

    .form-text {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $standard-black;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        padding-top: 0px;
        margin-top: 0px;
        margin-bottom: 15px;
      }

      &.cell-number {
        margin-top: 0px;
      }

      &.form-group {
        label {
          @media (max-width: 768px) {
            font-family: "NotoSans-Regular";
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: $standard-black;

            @media (max-width: 768px) {
              line-height: 0px;
              margin-top: 25px;
            }
          }
        }

        .display-asterisk {
          color: $red;
          margin-left: 2px;
        }

        .form-control {
          background: $shadowGrey;
          border: 1px solid $lightGrey;
          border-radius: 4px;
          line-height: 30px;
          text-align: left;
          height: 48px;

          &:disabled {
            color: #afb3c2;
            border: 1px solid #f0f2f7;
          }

          @media (max-width: 768px) {
            width: 100%;
            height: 48px;
          }
        }

        input::placeholder {
          font-size: 16px;
          color: #ced1dd;
        }
      }

      .input-group {
        width: 100%;
        flex-wrap: nowrap;

        .input-group-prepend {
          .input-group-text {
            height: 48px;
            width: 48px;
            border-radius: 0px;
            background: $shadowGrey;
            border: 1px solid $lightGrey;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 15px;

            @media (max-width: 768px) {
              height: 40px;
              margin: 0 0 20px 0px;
            }
          }
        }
      }
    }

    label {
      &.custom-label {
        color: $standard-black;
        font-family: "NotoSans-Regular";
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 0.25rem;

        .display-asterisk {
          color: $red;
          margin-left: 2px;
        }
      }
    }

    .date-of-birth-label {
      font-size: 12px;
      max-height: 20px;
    }

    .cell-number-row {
      line-height: normal;

      @media (max-width: 768px) {
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .react-select-bs {
        .react-select-bs__control {
          border: 1px solid $lightGrey;
          background: $shadowGrey;
          height: 48px;

          @media (max-width: 768px) {
            height: 40px;
          }

          .react-select-bs__value-container {
            border-radius: 4px;
            line-height: 30px;
            text-align: left;
            background: url(../../App/assets/img/arrow-down-solid.png) no-repeat right;
          }
        }

        .react-select-bs__indicators {
          display: none;
        }

        .react-select-bs__menu {
          color: $black;
        }
      }
    }

    .province-dropdown {
      line-height: normal;
      margin-bottom: 30px;

      @media (max-width: 768px) {
        margin-bottom: 10px;
        margin-top: 10px;
      }

      .react-select-bs {
        .react-select-bs__control {
          border: 1px solid $lightGrey;
          border-radius: 4px;
          line-height: 30px;
          text-align: left;
          background:
            url(../../App/assets/img/dropdown-blue.png) no-repeat 97% 50%,
            $shadowGrey;

          &.react-select-bs__control--menu-is-open {
            background: url(../../App/assets/img/drop-up-arrow.png) no-repeat 97% 50%;
          }
        }

        .react-select-bs__indicators {
          display: none;
        }
      }
    }

    .cell-control {
      &.form-group {
        .form-control {
          background: $shadowGrey;
          border: 1px solid $lightGrey;
          border-radius: 4px;
          line-height: 30px;
          text-align: left;
          height: 46px;
          width: 100%;

          @media (max-width: 768px) {
            line-height: 26px;
            width: 100%;
            height: 40px;
          }
        }
      }
    }
  }

  .custom-control-label {
    font-size: 12px;
  }

  .align-form-group-row {
    &.row {
      .row {
        .col-md-6 {
          padding-left: 0px;
        }
      }
    }
  }

  .identification-title-text {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $standard-black;
  }

  .radio-option {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: $standard-black;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 2%;
  }

  .align-communication-method {
    .custom-control {
      &.custom-radio {
        &.custom-control-inline {
          width: 100%;
          display: flex;
          align-items: center;

          input[type="radio"] {
            accent-color: $azureBlue;
          }

          .custom-control-input {
            margin-right: 3%;
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .react-select-bs {
    .react-select-bs__control {
      @media (max-width: 768px) {
        width: 100%;
        height: 40px;
      }
    }
  }

  .css-g1d714-ValueContainer {
    @media (max-width: 768px) {
      height: 40px;
    }
  }

  .align-communication-method {
    .react-select-bs {
      .css-yk16xz-control {
        @media (max-width: 768px) {
          height: 40px;
          width: 111%;
        }

        .css-g1d714-ValueContainer {
          @media (max-width: 768px) {
            height: 40px;
          }
        }
      }
    }
  }

  .align-cell-label {
    &.form-label {
      @media (max-width: 768px) {
        font-size: 12px;
        margin: 20px 0px 5px 7px;
      }
    }
  }

  .footer-button {
    margin-top: 10px;

    &.update {
      margin-right: 2%;

      @media (max-width: 768px) {
        margin-right: 0%;
      }
    }
  }

  .seperating-line-max {
    &.margin-top {
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
  }
}