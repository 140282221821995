@import "../../App/Styles/Variables.scss";

.employer-complaints {
  .background-card {
    &.body-card-complaints {
      margin: 40px 15px 30px 15px;
      padding: 8px 35px;
    }

    &.body-card-complaints-cu {
      margin-top: 10px;
      padding: 20px 35px;
    }

    &.body-card-complaints-tabs {
      margin-left: 15px;
      padding: 0px 12px 10px 12px;
    }
  }

  .show-complain {
    display: block;
  }

  .react-select-bs__option {
    background: none;

    &.react-select-bs__option--is-focused {
      background: none;
    }

    &.react-select-bs__option--is-selected {
      background: rgba(4, 14, 40, 0.0411932);
      color: black;
    }
  }

  .hide-complain {
    display: none;
  }

  .tel-control {
    &.form-control {
      width: 132%;
      height: 48px;
    }
  }

  .btn-row {
    justify-content: normal;
  }

  .row {
    .divider-complain {
      width: 99%;
      border: 1px solid $lightPurpleGrey;
      margin-left: 0px;
      padding-left: 0px;
    }
  }

  .align-contact-complain-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 2px solid $azureBlue;
    width: 99%;
    border-radius: 4px;
    margin-left: 0.5rem;

    &.col-md-6 {
      width: fit-content;
      padding-left: 0%;
      padding-right: 0%;
    }

    .btn-contact {
      border: none;
      border-radius: 0px;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;

      &.btn {
        background-color: $azureBlue;
        color: $white;
        width: 185px;
        height: 48px;
        --bs-gradient: none;
      }

      &.btn-primary {
        --bs-btn-hover-bg: $azureBlue;
        --bs-btn-hover-color: $white;
      }
    }

    .btn-complain {
      box-sizing: border-box;
      border-radius: 0px;
      width: auto;
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 1.23px;

      &.btn {
        background-color: $white;
        color: $azureBlue;
        border: none;
        width: 185px;
        height: 48px;
        --bs-gradient: none;
      }

      &.btn-primary {
        --bs-btn-hover-bg: $white;
        --bs-btn-hover-color: $azureBlue;
      }
    }
  }

  .card-header-complaint {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $standard-black;
  }

  .selected-value {
    font-weight: 500;
    font-size: 16px;
    background: rgba(4, 14, 40, 0.0411932);
  }

  .card-header-query {
    font-family: "NotoSans-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 35px;
    margin-top: 1%;
    margin-bottom: 0px;
    letter-spacing: 0.2px;
    color: $standard-black;
  }

  .row {
    &.search-by {
      margin: 10px 0px 30px 0px;
    }
  }

  .neo-card {
    &.card {
      height: 48px;
      background: url("../../App/assets/img/img-search.png") no-repeat 1% 50%, $shadowGrey;

      .card-body {
        padding: 0px 10px;

        .react-select-bs {
          height: 48px;

          &.search-bar {
            &.css-2b097c-container {
              .react-select-bs__control {
                height: 40px;
                background: $shadowGrey;
                border: none;
                margin-top: 4px;
                margin-left: 15px;
                box-shadow: none;

                .react-select-bs__indicators {
                  display: none;
                }
              }

              .react-select-bs__menu {
                margin-left: -22px;
                margin-top: 0px;
                width: 103.5%;

                @media (max-width: 1500px) {
                  width: 105%;
                }

                .react-select-bs__menu-list {
                  font-family: "NotoSans-Regular";
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 19px;
                  color: $standard-black;
                }
              }
            }
          }
        }
      }
    }
  }

  .tab-content {
    .tab-pane {
      padding-bottom: 0px;
    }
  }

  .complaints-tabs {
    margin-top: 10px;
    padding: 10px 10px 0px 10px;

    .tabs-header {
      margin-bottom: 20px;

      .nav {
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        --bs-nav-link-color: #0d1325;
        --bs-nav-link-hover-color: #0d1325;

        &.nav-tabs {
          font-family: "NotoSans-Regular";
          font-weight: 400;
          font-size: 14px;
          line-height: 35px;
          text-align: center;
          color: $standard-black;

          .nav-link {
            border: none;
            padding-bottom: 12px;

            &.active {
              font-family: "NotoSans-SemiBold";
              font-weight: 600;
              border-bottom: 4px solid $azureBlue;
              color: $standard-black;
            }
          }
        }
      }
    }
  }

  .btn-filter {
    color: $white;
    --bs-gradient: none;
    background-color: $azureBlue;
    border-color: $azureBlue;
    height: 48px;
    width: 15.6%;
    margin-left: 1%;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 13.3%;
    }

    &.btn:hover {
      background-color: $azureBlue;
      --bs-gradient: none;
      color: white;
    }
  }

  .detail-cards {
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    border-left: 7px solid $azureBlue;
    padding: 2px 0px 2px 15px;
    margin: 30px 0px;
    height: 92px;
    width: 100%;

    .detail-cards-heading {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 18px;
      line-height: 31px;
      color: #424757;
    }

    .detail-cards-text {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 14px;
      line-height: 27px;
      color: $standard-black;
    }
  }

  .separating-line-max {
    margin-left: -22px;
    width: 103%;

    @media (max-width: 1500px) {
      margin-left: -22px;
      width: 104%;
    }

    @media (max-width: 1500px) {
      margin-left: -22px;
      width: 105%;
    }

    &.margin-top {
      margin: 80px 0px 10px -22px;
    }
  }

  .grey-upload-box {
    background: #f0f1f4;
    border-radius: 4px;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $standard-black;
    margin: 10px;
    padding: 10px;

    .grey-upload-box-text {
      .float-right {
        float: right;

        &.hover {
          cursor: pointer;
        }
      }

      &.floar-right {
        float: right;
      }
    }
  }

  .queries-css {
    .email-control {
      .form-control {
        width: 99.5%;
      }
    }

    .tel-control-queries {
      .form-control {
        width: 115%;
      }
    }
  }

  .compliments-css {
    .divider1 {
      &.margin-top {
        margin: 10px 0px;
      }
    }

    .row {
      &.pad-lr {
        padding: 0px 35px;
      }
    }
  }

  .nav-link {
    width: 130px;
  }
}

.background-card {
  &.employer-complaint-summary {
    padding-bottom: 25px;
  }
}