@import "../../App/Styles/Variables.scss";

.employer-member-profile-view-css {
  .scheme-information-header-employer {
    position: center;
    top: 98px;
    height: max-content;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    margin: 40px 15px 10px 15px;

    @media (min-width: 800px) and (max-width: 1200px) {
      margin-top: 30px;
      margin-left: 5px;
    }

    @media (max-width: 768px) {
      margin: 25px 15px 5px 5px;
    }

    .scheme-card-header {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      letter-spacing: 0.2px;
      color: $standard-black;
      padding-left: 0px;
      margin: 15px 10px 010px 25px;

      .scheme-card-header-bold {
        color: $standard-black;
        font-family: "NotoSans-Regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 35px;
        letter-spacing: 0.2px;
        font-feature-settings:
          "clig" off,
          "liga" off;
      }
    }

    .divider-employer-header {
      width: 100%;
      height: 1px;
      background: $greyLine;
    }

    .employer-member-profile-dropdown {
      color: $black;
      margin: 20px 30px 20px 30px;

      .employer-card-header {
        color: $standard-black;
        font-family: "NotoSans-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0.2px;
      }

      .css-1hb7zxy-IndicatorsContainer {
        display: none;
      }

      .css-yk16xz-control {
        font-family: "NotoSans-Regular";
        box-sizing: border-box;
        background: $shadowGrey;
        border: 1px solid #afb3c2;
        border-radius: 4px;
        height: 48px;
        color: $standard-black;
      }

      .react-select-bs__control--menu-is-open {
        height: 48px;
      }

      .react-select-bs__control--is-focused {
        height: 48px;
      }

      .react-select-bs__value-container {
        background: url(../../App/assets/img/drop-down-arrow.png) no-repeat right;
        background-position-x: 97%;
        background-position-y: 50%;
        height: 45px;
      }

      .css-b8ldur-Input {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #afb3c2;

        .react-select-bs__input {
          :input {
            width: 110px;
          }
        }
      }

      .css-4ljt47-MenuList {
        padding-bottom: 0px;
        padding-top: 0px;
      }

      .dropdowm-label {
        margin-top: 0px;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
      }

      .css-1uccc91-singleValue {
        width: 86%;
      }
    }
  }

  .employer-profile-css {
    position: center;
    top: 98px;
    height: max-content;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    margin: 15px 15px 30px 15px;

    @media (min-width: 800px) and (max-width: 1200px) {
      margin-top: 30px;
      margin-left: 5px;
    }

    @media (max-width: 768px) {
      margin: 25px 15px 5px 5px;
    }

    .line-profile-employer {
      margin-top: 2rem;
      margin-bottom: 2rem;
      margin-left: 4%;
      width: 92%;
      fill: $gray;
      border: 1px solid $gray;
    }

    .display-alert-profile-employer {
      &.alert {
        background: url(../../App/assets/img/info.png) no-repeat 1% 45%;
        margin: 30px 70px 20px 70px;
        background-size: 23px;
        padding-left: 50px;

        h6 {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: $standard-black;
          padding-top: 5px;
        }
      }

      &.alert-primary {
        background-color: #deecff;
        box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
        border: none;
      }

      @media (min-width: 768px) and (max-width: 1200px) {
        &.alert {
          background-position-y: 47%;
          background-size: 2.5%;
          padding-left: 4%;
          margin-bottom: 0px;

          h6 {
            font-size: 14px;
          }
        }
      }
    }

    .align-form-group-row-profile {
      margin-left: 70px;
      margin-right: 70px;

      &.add-background-color {
        border-radius: 10px;
        background: #eef0f5;
      }

      .align-address-box {
        padding: 20px 30px 20px 30px;

        .address-header {
          color: $standard-black;
          font-family: "NotoSans-Regular";
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 35px;
          letter-spacing: 0.2px;
        }
      }

      .form-group {
        .form-control {
          height: 48px;
          width: 100%;
          color: $lightGrey;
          background-color: $shadowGrey;
          font-size: 18px;
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          border-radius: 4px;
          border: 1px solid $lightGrey;

          &:disabled {
            color: #afb3c2;
            border: 1px solid #f0f2f7;
            width: 100%;

            @media (max-width: 768px) {
              width: 96%;
            }
          }
        }

        label {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $standard-black;
          margin-bottom: 1%;
          margin-top: 2rem;

          @media (max-width: 768px) {
            margin-top: 1.5rem;
          }
        }
      }

      .employer-member-profile-dropdown {
        color: $black;
        margin-top: 2rem;

        .react-select-bs {
          &.react-select-bs {
            &.react-select-bs--is-disabled {
              border-radius: 4px;
              height: 48px;

              .react-select-bs__control {
                background-color: $shadowGrey;
                height: 48px;
                border: 1px solid $shadowGrey;

                .react-select-bs__indicators {
                  display: none;
                }
              }
            }
          }
        }

        .employer-card-header {
          color: $standard-black;
          font-family: "NotoSans-Regular";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 35px;
          letter-spacing: 0.2px;
        }

        .css-1hb7zxy-IndicatorsContainer {
          display: none;
        }

        .react-select-bs__indicators {
          display: none;

          &.css-1hb7zxy-IndicatorsContainer {
            display: none;
          }
        }

        .css-yk16xz-control {
          font-family: "NotoSans-Regular";
          box-sizing: border-box;
          background: $shadowGrey;
          border: 1px solid #afb3c2;
          border-radius: 4px;
          height: 48px;
          color: $standard-black;
        }

        .react-select-bs__control--menu-is-open {
          height: 48px;
        }

        .react-select-bs__control--is-focused {
          height: 48px;
        }

        .css-b8ldur-Input {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #afb3c2;

          .react-select-bs__input {
            :input {
              width: 110px;
            }
          }
        }

        .css-4ljt47-MenuList {
          padding-bottom: 0px;
          padding-top: 0px;
        }

        .css-g1d714-ValueContainer {
          color: black;
          background: url(../../App/assets/img/drop-down-arrow.png) no-repeat 98% 50%;
        }

        .dropdowm-label {
          margin-top: 0px;
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
        }

        .css-1uccc91-singleValue {
          width: 86%;
        }
      }
    }

    .line-employer-header {
      width: 100%;
      height: 1px;
      background: $greyLine;
      margin-top: 50px;
      margin-bottom: 19px;
    }

    .container-div {
      margin: 0px 0px 20px 0px;
      align-items: center;
      display: grid;
      grid-template-columns: auto 1fr auto;
    }

    .left-div {
      .back-to-port {
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 35px;
        letter-spacing: 0.2px;
        color: $azureBlue;
        text-decoration: none;
        margin-top: 0rem;
        margin-left: 1.5rem;
        margin-bottom: 1rem;

        @media (max-width: 768px) {
          line-height: 52px;
          display: flex;
          justify-content: center;
          margin-left: 0px;
        }
      }
    }

    .right-div {
      padding: 10px;
      justify-self: end;

      .cancel-btn-css {
        background: $white;
        color: $azureBlue;
        border-radius: 4px;
        width: 176px;
        margin: 10px;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1.23px;
        height: 40px;

        &.btn:hover {
          background-color: $azureBlue;
          --bs-gradient: none;
          color: $white;
        }

        &.update-btn {
          background: $azureBlue;
          color: $white;
        }
      }
    }

    .alert {
      --bs-alert-margin-bottom: 0px;
    }
  }
}