@import "../../App/Styles/Variables.scss";

.third-party-dashboard-container {
  .third-party-dashboard-card {
    width: auto;
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    height: auto;
    border-radius: 5px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 30px 15px 30px 5px;
  }

  .third-party-dashboard-card {
    .third-party-dashboard-card-heading {
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 35px;
      letter-spacing: 0.2px;
      color: $standard-black;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 35px;
    }

    .third-party-dashboard-card-sub-heading {
      font-family: "NotoSans-Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: $standard-black;
      margin-top: 0px;
      padding-left: 2px;
      margin-bottom: 0px;
      padding-bottom: 20px;
    }

    .third-party-dashboard-tab-member-search-staff {
      .member-search-form-group {
        &.remove-padding-for-member-search {
          padding-left: 0px;
        }

        label {
          color: $standard-black;
        }

        .form-control {
          height: 42px;
          background: url(../../App/assets/img/img-search.png) no-repeat left center;
          padding-left: 1.5em;
        }
      }

      .card {
        width: 15%;
        margin-bottom: 0%;
        height: 42px;
        margin-top: 1.4rem;

        .card-body {
          padding: 7px 5px 5px 5px;

          .align-scheme-title {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            .filter-by-status-heading {
              width: max-content;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              font-weight: 500;
              color: $azureBlue;

            }
          }
        }
      }

      .btn-filter {
        margin-top: 1.4rem;
        width: 8%;
      }

      .row {
        &.adjust-row-member-search {
          margin-left: 0px;
          margin-top: 5px;
          width: 98%;
          justify-content: flex-end;
        }

        .adjust-col-member-search {
          width: 33%;
          padding-left: 0px;
          padding-right: 32px;
        }
      }
    }

    .third-party-dashboard-tabs {
      margin-top: 10px;
      padding: 10px;

      .tabs-header {
        margin-bottom: 14px;

        .nav {
          font-family: "NotoSans-Regular";
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          --bs-nav-link-color: $standard-black;
          --bs-nav-link-hover-color: $standard-black;

          &.nav-tabs {
            line-height: 35px;
            color: $standard-black;

            .nav-link {
              border: none;
              padding-bottom: 12px;
              margin-right: 40px;

              &.active {
                font-family: "NotoSans-SemiBold";
                font-weight: 600;
                border-bottom: 4px solid $azureBlue;
                color: $standard-black;
              }

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .card {
      position: relative;
      padding-left: 0px;
      margin-bottom: 0.7%;
      height: 42px;
    }

    .card-body {
      height: 42px;
      padding-top: 5px;
      padding-left: 3px;
    }

    .btn-filter {
      color: $white;
      --bs-gradient: none;
      background-color: $azureBlue;
      border-color: $azureBlue;
      height: 42px;
      width: 7%;
      margin-left: 1%;
      letter-spacing: normal;
      font-size: 16px;
    }

    .scheme-filter-card {
      width: 15%;
      margin-left: 1%;
      height: 42px;
      padding-right: 0%;

      .card-body {
        padding: 7px 5px 5px 5px;

        .align-scheme-title {
          display: flex;
          justify-content: space-between;
          flex-direction: row;

          .filter-by-status-heading {
            width: max-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 500;
            color: $azureBlue;

          }
        }
      }
    }

    .row {
      &.padding-bottom-radio {
        margin-bottom: 20px;

        .expand-col-surface {
          margin-left: 20px;

          @media (max-width: 1500px) {
            width: 21%;
          }
        }
      }
    }

    .filter-header {
      font-family: "NotoSans-Semibold";
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $standard-black;
      margin: 15px 0px 35px 0px;
    }

    .custom-control {
      &.custom-checkbox {
        .custom-control-input {
          margin-bottom: 20px;
        }

        .custom-control-label {
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $standard-black;
          margin-left: 10px;
        }
      }
    }

    .selected-pill-items {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $standard-black;
      margin: 2% 0%;

      .background-pill {
        width: fit-content;
        background: $lightBlueGrey;
        border-radius: 16px;
        height: 28px;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        margin-right: 10px;

        .close-button {
          display: flex;
          float: right;
          background: url(../../App/assets/img/cross-grey.png) no-repeat 96% 50%;
          margin-right: 8px;

          &.btn {
            &.btn-primary {
              --bs-btn-bg: none;
              --bs-btn-border-color: none;
            }
          }
        }

        .align-pill-inner-text {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 80px;
          display: block;
        }
      }
    }

    .empty-grid-container {
      height: 300px;
      background-color: $main-background;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .grid-info {
        font-family: "NotoSans-Regular";
        font-size: 16px;
        letter-spacing: 0.2px;
        color: $lighterGrey;
        padding-top: 30px;
      }

      @media (max-width: 768px) {
        height: 392px;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

        .grid-info {
          margin-bottom: 0px;
          text-align: center;
        }
      }
    }

    .filter-inline-checkboxes {
      font-family: "NotoSans-Medium";
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: $standard-black;

      .custom-control {
        display: flex;
      }

      input {
        height: 18px;
        width: 18px;
        min-width: 18px;
        accent-color: $azureBlue;
        margin-right: 10px;
      }
    }

    .row {
      &.adjust-row {
        width: 100%;
        justify-content: flex-end;
      }

      .adjust-col {
        width: 33%;
        padding-left: 0px;
        padding-right: 32px;
      }
    }

    .square-box {
      margin-left: 5px;
      width: 20px;
      height: 20px;
      background-color: #deecff;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: nowrap;
      align-items: center;
      border-radius: 4px;
    }

    .row {
      &.search-by {
        margin-left: 0px;
      }
    }

    .search-dropdown {
      display: inline-block;
      width: 93%;

      .form-control {
        border: none;
        width: 100%;
        background: url(../../App/assets/img/img-search.png) no-repeat 1% 50%;
        padding-left: 40px;
        line-height: 18px;

        &:focus {
          border: none;
          box-shadow: none;
        }
      }

      label {
        display: none;
      }
    }

    .align-filter {
      float: right;
      padding-top: 5px;
      background: none;
      border: none;
      padding-right: 0px;

      &.btn {
        border: none;

        &:hover,
        :focus {
          border: none;
          background: none;
        }
      }
    }

    .card-header {
      font-size: 16px;
      font-weight: 500;
      color: $azureBlue;
      background-color: $white;
      background: url(../../App/assets/img/img-slider-controls.png) no-repeat 99% 50%;
    }

    .float-content {
      position: absolute;
      z-index: 10000;
      width: -webkit-fill-available;
    }

    .show-dropdown {
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      height: auto;
      padding: 10px;
    }

    .scheme-options-align {
      margin-left: 18px;
    }

    .table {
      thead {
        tr {
          th {
            background: none;
            border: none;
            color: $standard-black;
            font-family: "NotoSans-SemiBold";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            text-align: left;

            .column-sort-icon {
              position: relative;
              margin: 0 0 0 10px;
            }
          }
        }
      }

      tbody {
        tr {
          height: 60px;
          background: $white;
          box-shadow: 0px 1px 4px rgb(139 146 167 / 50%);
          border-radius: 4px;
          border: none;

          td {
            border: none;
            vertical-align: middle;
            font-size: 12px;
            padding-left: 10px;

            &.border-three-dots {
              border-left: 1px solid $lightBlueGrey;
              text-align-last: center;

              .dropdown-three-dots {
                .btn-group {
                  background: url(../../App/assets/img/android.png) no-repeat 98.2% 50%;

                  .dropdown-toggle::after {
                    display: none;
                  }

                  .dropdown-menu {
                    background: $white;
                    box-shadow:
                      0px 0px 2px rgba(0, 0, 0, 0.03),
                      0px 6px 10px rgba(0, 0, 0, 0.08);
                    border-radius: 5px;
                    border: none;
                    --bs-dropdown-link-hover-bg: none;
                    --bs-dropdown-link-active-color: $standard-black;
                    --bs-dropdown-link-active-bg: none;

                    .dropdown-item {
                      display: flex;
                    }

                    .align-kebab-image {
                      margin-right: 2%;
                      margin-bottom: 1px;
                    }
                  }

                  .btn {
                    border: none;

                    &.btn-primary {
                      border: none;
                      --bs-btn-color: none;
                      --bs-btn-bg: none;
                      --bs-btn-hover-bg: none;
                      --bs-btn-active-color: none;
                      --bs-btn-active-bg: none;
                    }
                  }
                }
              }
            }

            &.id-num-col {
              text-align: left;
            }

            .set-scheme-length {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              width: 215px;
              display: inline-block;

              @media (max-width: 1367px) {
                width: 125px;
              }
            }
          }
        }
      }
    }

    .neo-pager {
      table {
        border-collapse: unset;
        border-spacing: 0px 15px;
      }

      .table {
        &.neo-grid {
          thead {
            display: contents;
          }
        }
      }
    }
  }

  .pill-container {
    display: flex;
    min-height: 60px;
    align-items: center;

    &.search-dashboard {
      justify-content: center;
    }

    .pills {

      &-active,
      &-pending,
      &-terminated {
        border: none;
        color: $white;
        text-align: center;
        display: inline-block;
        border-radius: 4px;
        width: 125px;
        height: 34px;
        align-content: center;
        white-space: nowrap;
        overflow: hidden;
        word-wrap: break-word;
        text-overflow: ellipsis;
        vertical-align: middle;

        @media (max-width: 1194px) {
          width: 100%;
          font-size: 12px;
        }

        @media (max-width: 468px) {
          width: 100%;
          font-size: 14px;
        }

        @media (max-width: 360px) {
          width: 100%;
          font-size: 13px;
        }
      }

      &-active {
        background-color: $darkerGreen;

        &.search-dashboard-pill {
          min-width: 150px;
        }
      }

      &-pending {
        background-color: $amber;
        padding: 2px 4px 4px 4px;
        cursor: pointer;

        @media (max-width: 1194px) {
          white-space: break-spaces;
          padding: 2% 2%;
          font-size: 10px;
        }
      }

      &-terminated {
        background-color: $warningRed;
        padding: 2px 4px 4px 4px;
        cursor: pointer;

        @media (max-width: 1194px) {
          white-space: break-spaces;
          padding: 2% 2%;
          font-size: 10px;
        }
      }
    }
  }
}