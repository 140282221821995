.menu-overlay {
  transition: none;
  display: none;
}

.divider-thin-sidebar {
  width: 100%;
  height: 1px;
  border-radius: 0.5px;
  background: $lightPurpleGrey;
  left: 0px;
  right: 0px;
  top: 15px;
  bottom: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.sidebar {
  .sidebar-header {
    .divider-thin-sidebar {
      display: none;
    }

    .display-notification {
      display: none;
    }

    .logo {
      .sidebar-logo-img {
        width: 133px;
        height: 50px;
        margin: 2% 2% 2% 18%;
      }

      img {
        width: auto;
        height: 100%;
      }

      .align-edit-link {
        width: 100%;
        margin-top: 10px;
        padding-right: 30px;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-end;
        justify-content: flex-end;
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 14px;
        letter-spacing: 0.428571px;
        color: $white;

        @media (max-width: 992px) {
          padding-top: 11px;
        }
      }

      .align-edit-icon {
        width: 10px;
        height: 10px;
        margin-right: 1px;
      }

      .user-display {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-content: stretch;
        align-items: center;
        margin: 7% auto 7% 9%;

        &-edit {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-content: stretch;
          align-items: center;
          margin: 0% auto 7% 9%;
        }
      }

      .display-user {
        display: none;
      }

      .divider {
        width: 90%;
        margin-left: 4%;
        height: 1px;
        background: $lightPurpleGrey;
        border-radius: 0.5px;
        left: 0px;
        right: 0px;
        top: 15px;
        bottom: 15px;
        margin-bottom: 5px;
        margin-top: 5px;

        &.divider-top {
          margin-top: 4%;
        }
      }
    }

    .user-info {
      margin-left: 7%;
      color: $white;
      font-family: "NotoSans-SemiBold";
      font-size: 14px;

      p {
        margin-bottom: 0px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 150px;
      }

      @media (min-width: 993px) and (max-width: 1194px) {
        p {
          width: 120px;
        }
      }
    }

    .divider {
      width: 100%;
      height: 1px;
      background: $lightPurpleGrey;
      border-radius: 0.5px;
      left: 0px;
      right: 0px;
      top: 15px;
      bottom: 15px;
      margin-bottom: 10px;

      &.divider-top {
        margin-top: 4%;
      }
    }
  }
}

.sidebar {
  background: $component-header-background;
  color: $white;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  font-size: 1rem;
  position: relative;

  img {
    width: 70%;
    color: $white;
  }

  .sidebar-header {
    flex: 0 0 auto;
    width: 100%;
    transition: width ease-in-out $menu-animation-speed;
    font-size: 0;
  }

  .sidebar-content {
    flex: 1;
    height: auto;

    h5 {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: $lightPurpleGrey;
      margin-top: 6%;
      margin-left: 8%;
    }

    .employer-menu-item {
      height: 80%;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    overflow: hidden;

    &.menu-level-1 {
      padding-bottom: 1rem;
    }

    &.menu-level-3 {
      padding-bottom: 2.5rem;
    }

    li {
      list-style-type: none;
      position: relative;
      width: $menu-width;
    }

    .menu-item {
      position: relative;

      &.has-children {
        cursor: pointer;
      }

      &:hover {

        a,
        span.static-item {
          color: $white;
          width: 93%;
          margin-left: 10px;
          border-radius: 4px;
          cursor: pointer;
        }

        color: $white;
      }

      div.menu-expander {
        text-align: center;
        position: absolute;
        right: 9%;
        top: 7px;
        cursor: pointer;
        border-radius: 5px;
        width: 24px;
        height: 24px;
        user-select: none;

        > i {
          font-size: 16px;
          color: $bs-primary;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      a.active {
        background-color: $hoverBlue;
        color: $white;
        border: 1px solid $hoverBlue;
        width: 93%;
        margin-left: 10px;
        border-radius: 4px;
      }

      a,
      span.static-item {
        color: $white;
        display: block;
        padding: 0px;
        text-decoration: none;
        transition: padding ease-in-out $menu-animation-speed;
        font-weight: 400;
        font-size: 14px;
        margin-left: 10px;
      }

      .sidebar-icon-container {
        display: inline;
        position: relative;

        .sidebar-icon {
          padding: 9px 0;
          width: 33px;
          border-radius: 50%;
          color: $bs-primary;
          margin-right: 8px;

          &.fake-icon {
            font-weight: 500;
            display: inline-block;
            text-align: center;
            line-height: 1;
          }
        }

        .sidebar-mini-expander {
          position: absolute;
          color: $white;
          font-size: 25px;
          left: 29px;
          display: none;
        }
      }

      &.is-expanded {
        .sidebar-icon-container {
          .sidebar-mini-expander {
            top: 6px;
            left: 9px;
          }
        }
      }
    }
  }

  ul.menu-level-2 {
    padding-bottom: 0.375rem;

    .menu-item {
      .sidebar-icon {
        font-size: 12px;
        width: 31px;
        margin-right: 10px;
      }

      .sidebar-mini-expander {
        left: 27px;
      }

      a,
      span.static-item {
        font-size: 12.5px;
        padding: 7px 2px 7px 2px;
      }

      &.is-expanded {
        .sidebar-mini-expander {
          top: 4px;
          left: 8px;
        }
      }

      a.active {
        background-color: $hoverBlue;
        color: $white;
        border: 1px solid $hoverBlue;
        width: 5px;
        padding: 7px 2px;
        white-space: nowrap;
        margin-left: 10px;
        border-radius: 4px;
      }
    }
  }

  .sidebar-menu-footer {
    width: 100%;
    position: relative;
    bottom: 0;
    left: 0;
    background: $component-header-background;

    h5 {
      font-family: "NotoSans-Regular";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: $lightPurpleGrey;
      margin-top: 6%;
      margin-left: 8%;
    }

    .divider {
      width: 90%;
      margin-left: 4%;
      height: 1px;
      background: $lightPurpleGrey;
      border-radius: 0.5px;
      left: 0px;
      right: 0px;
      top: 15px;
      bottom: 15px;
      margin-bottom: 5px;
      margin-top: 5px;

      &.divider-top {
        margin-top: 4%;
      }
    }

    .info {
      font-family: "NotoSans-Regular";
      font-weight: 400;
      font-size: 12px;
      display: inline-block;
      margin: 10px 14px;
      color: $white;
    }
  }
}

/* Make all levels have the same size */
.sidebar-fixed-item-size {
  ul.menu-level-2 {
    .menu-item {

      a,
      span.static-item {
        font-size: 13.5px;
        padding: 10px 10px 10px 12px;
      }
    }
  }

  ul.menu-level-3 {
    padding: 0;

    .menu-item {

      a,
      span.static-item {
        font-size: 13px;
        padding: 10px 10px 10px 17px;
      }
    }
  }
}

.thin-sidebar {
  .sidebar {
    height: fit-content;

    .sidebar-content {
      margin-top: 0%;
    }

    .sidebar-header {
      .divider-thin-sidebar {
        display: flex;
      }

      .divider {
        display: none;
      }

      .logo {
        .display-user {
          margin-top: 5px;
          display: flex;
        }

        img {
          display: none;
        }

        .profile-image {
          img {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-left: 5%;
            height: 50px;

            .align-notifications {
              flex-direction: row;
              flex-wrap: nowrap;
              align-items: center;
            }

            .notifications-text {
              color: $white;
              font-style: Normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              font-family: "NotoSans-Regular";
            }

            .bell {
              font-size: 14px;
              cursor: pointer;
              display: flex;
              justify-content: right;
              align-items: center;
              width: auto;

              .icon {
                color: $white;
                font-size: 2em;
                -webkit-text-stroke: 1px $azureBlue;
              }

              .notification-bubble {
                margin-left: -10px;
                background-color: $pink;
              }
            }
          }
        }

        .display-user {
          img {
            display: flex;
            padding-left: 50%;
          }
        }
      }
    }

    .display-notification {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 5%;

      i {
        height: 23px;
        width: 24px;

        img {
          display: flex !important;
          position: relative;
        }

        .align-ellipse-mobile {
          position: absolute;
          height: 19px;
          left: 26px;
          top: 101px;
        }

        .align-number-mobile {
          position: absolute;
          left: 29px;
          top: 102px;
          font-family: "NotoSans-Bold";
          font-style: normal;
          font-weight: 700;
          font-size: 10px;
          line-height: 14px;
          color: $white;
        }
      }

      p {
        color: $white;
        font-style: Normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: "NotoSans-Regular";
        margin-top: 7%;
        margin-left: 5%;
      }
    }

    .menu-item-text {
      transition: opacity ease-in-out $menu-animation-speed;
      opacity: 0;
    }

    ul .menu-item .sidebar-icon-container .sidebar-mini-expander {
      display: inline;
    }
  }

  .sidebar-menu-footer {
    margin-bottom: 0%;
  }
}

.sidebar-expanded {
  .sidebar {
    margin-top: 15%;

    .menu-item-text {
      opacity: 1;
    }

    ul .menu-item .sidebar-icon-container .sidebar-mini-expander {
      display: none;
    }
  }
}

div.sidebar-content > div > div:nth-child(3) > div {
  /* Move the fancy scrollbar to the edge, instead of having a 2px gap. */
  background-color: $bs-primary !important;
}

.notification {
  position: relative;

  .align-ellipse {
    position: absolute;
    left: 11px;
  }

  .align-number {
    position: absolute;
    left: 16px;
    top: 1px;
    font-family: "NotoSans-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 6px;
    color: $white;
  }
}

@include media-breakpoint-down(sm) {
  .menu-overlay {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    bottom: 0;
    background: rgba(9, 30, 66, 0.54);
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out $menu-animation-speed;
  }

  .sidebar-expanded {
    .menu-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

@media (max-width: 768px) {
  .thin-sidebar .sidebar .sidebar-content {
    padding-bottom: 0%;
  }

  .thin-sidebar .sidebar-menu-footer {
    margin-top: 0%;
  }
}