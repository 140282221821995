@import "./Variables.scss";

body {
  color: $white;
  font-size: $main-font-size;
}

.modal-xl {
  min-width: 800px;

  .modal-content {
    width: 100%;
    max-width: 100%;
  }
}

div.container-xl {
  max-width: 1160px;
  padding: 0px 15px;
  margin: 0px -15px;
  overflow: hidden;
}

.align-buttons {
  display: flex;
  justify-content: flex-end;

  .btn-cancel {
    box-sizing: border-box;
    background: $white;
    border: 2px solid $azureBlue;
    color: $azureBlue;
    border-radius: 4px;
    width: 23%;
    height: 40px;
    margin-right: 2%;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.23px;

    &.btn-primary {
      --bs-btn-active-color: $azureBlue;
      --bs-btn-active-bg: $white;
    }
  }

  .btn-continue {
    background: $azureBlue;
    color: $white;
    border-radius: 4px;
    width: 23%;
    margin-right: 2%;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    height: 40px;
    letter-spacing: 1.23px;

    &.btn-primary {
      --bs-btn-active-color: $white;
      --bs-btn-active-bg: $azureBlue;
    }
  }
}

.modal {
  .modal-container {
    .modal-dialog {
      .modal-content {
        width: 100%;
        max-width: 500px;
        height: 100%;
        display: flex;
        align-items: center;
        border: none;

        .modal-header {
          text-align: center;
          border: none;
          padding-top: 30px;
          padding-bottom: 10px;
          color: $standard-black;

          .modal-title {
            font-family: "NotoSans-SemiBold";
            font-size: 20px;
          }

          .close {
            display: none;
          }
        }

        .modal-body {
          max-width: 100%;
          font-family: "NotoSans-Regular";
          font-weight: 400;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          color: #0d1325;
          padding: 10px 50px 15px 50px;

          @media (max-width: 768px) {
            padding: 0px 30px 15px 30px;
          }

          .checked-img {
            width: 222px;
            height: 100px;

            @media (max-width: 450px) {
              width: 184px;
              height: 83px;
            }
          }
        }

        .modal-footer {
          border: none;
          width: 80%;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 15px;

          .btn {
            font-family: "NotoSans-Regular";
            font-weight: 400;
            font-size: 18px;
            width: 100%;

            &.btn-primary {
              color: $white;
              --bs-gradient: none;
              background-color: $azureBlue;
              border-color: $azureBlue;
            }

            &.btn-outline-primary {
              color: $azureBlue;
              background-color: $white;
              border-color: $white;
              --bs-gradient: none;
            }

            &.btn-secondary {
              color: $azureBlue;
              background-color: $white;
              border-color: $white;
              --bs-gradient: none;
            }

            &.btn-outline-danger {
              color: $azureBlue;
              background-color: $white;
              border-color: $white;
              --bs-gradient: none;
            }

            &.btn-light {
              color: $azureBlue;
              background: $white;
              border: 1px solid $azureBlue;
              border-radius: 4px;
            }

            &.btn-outline-light {
              color: $azureBlue;
              background: $white;
              border: 1px solid $azureBlue;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: $lightPurpleGrey;
  border-radius: 0.5px;
  left: 0px;
  right: 0px;
  top: 15px;
  bottom: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.app-header-panel {
  position: fixed;
  left: $menu-width;
  right: 0;
  z-index: 5;
  background: $white;
  font-size: 14px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.14);
  margin-left: auto;
  height: 60px;

  .display-notification {
    display: flex;
    margin-left: 10%;
    margin-top: 13%;
    color: $standard-black;
    font-size: 20px;

    i {
      height: 23px;
      width: 24px;

      img {
        display: flex;
        position: relative;
      }
    }
  }

  .page-progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    height: 6px;
    z-index: 10;
    width: 100%;
  }

  .mini-logo-display {
    display: none;
  }

  .app-header {
    height: 60px;

    .user-roles-image-container {
      display: none;
    }

    .display-header-panel-content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .app-hamburger-container {
    display: none;

    i {
      display: none;
    }
  }

  .show-on-desktop {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    margin-right: 10px;

    @media (max-width: 992px) {
      display: none;
    }

    .bell {
      font-size: 14px;
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-right: 30px;

      .icon {
        color: $white;
        font-size: 2em;
        -webkit-text-stroke: 1px $azureBlue;
      }

      .notification-bubble {
        margin-left: -10px;
        background-color: $pink;
      }
    }
  }

  .welcome-tag {
    /* H1 */
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 60px;
    letter-spacing: 0.133333px;
    color: $standard-black;
    letter-spacing: 0.133333px;
    padding-left: 42px;
    float: left;

    @media (max-width: 768px) {
      line-height: 60px;
    }

    @media (max-width: 400px) {
      line-height: 58px;
    }
  }

  .app-user-icon {
    font-size: 20px;
    width: 230px;
    padding: 1px 24px 1px 10px;
    cursor: pointer;
    position: relative;
    border-left: $lightPurpleGrey 1px solid;
    margin-top: 10px;
    float: right;

    .react-select-bs__control {
      border: none;
      height: 40px;
    }

    .react-select-row {
      padding: 1%;
    }

    .user-roles-image {
      float: left;
      margin-top: 6px;
    }

    .role-codes-dropdown {
      width: auto;
      margin-top: 0px;
      font-size: 16px;
      width: 95%;

      @media (max-width: 1500px) {
        width: 100%;
      }

      @media (min-width: 768px) and (max-width: 1194px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        width: 100%;
        margin-left: 0%;
      }

      .react-select-bs__indicators {
        display: none;
      }

      .react-select-bs__value-container {
        background: url(../../App/assets/img/drop-down-arrow.png) no-repeat right;
        background-position-x: 90%;
        background-position-y: 50%;
        height: 40px;
        cursor: pointer;
        background-color: #f5f7fb;
      }

      .react-select-bs__menu-list {
        color: $standard-black;
        padding-bottom: 3px;
        padding-top: 0px;
      }

      .react-select-bs__option {
        background: none;

        &.react-select-bs__option--is-focused {
          background: none;
        }

        &.react-select-bs__option--is-selected {
          background: rgba(4, 14, 40, 0.0411932);
          color: black;
        }
      }

      .react-select-row.selected {
        background: rgba(4, 14, 40, 0.0411932);
        color: $standard-black;
      }

      .react-select-row.focused {
        background: rgba(4, 14, 40, 0.0411932);
        color: $standard-black;
      }

      .react-select-bs__control--is-focused {
        border: none;
        box-shadow: none;
      }

      .react-select-bs__control--menu-is-open {
        background-color: #f5f7fb;

        .react-select-bs__value-container {
          background: url("../../App/assets/img/drop-up-arrow.png") no-repeat right;
          background-position-x: 90%;
          background-position-y: 50%;
          background-size: 18px;
          background-color: #f5f7fb;

          @media (min-width: 768px) and (max-width: 1194px) {
            background-position: right;
          }

          @media (max-width: 768px) {
            background-position: right;
          }
        }
      }
    }

    .header-right-down {
      &.form-group {
        .form-control {
          background-position: right;
          background-image: url("../../App/assets/img/drop-down-arrow.png");
          background-position-x: 90%;
          background-position-y: 50%;
          background-repeat: no-repeat;
          margin-top: 4px;
          border: none;
        }

        @media (max-width: 1500px) {
          margin-top: 4px;
        }
      }
    }

    .header-right-up {
      &.form-group {
        .form-control {
          background-position: right;
          background-image: url("../../App/assets/img/drop-up-arrow.png");
          background-position-x: 90%;
          background-position-y: 50%;
          background-repeat: no-repeat;
          margin-top: 4px;
          border: none;
        }

        @media (max-width: 1500px) {
          margin-top: 4px;
        }
      }
    }

    &:hover .app-user-card {
      display: inline-block;
      opacity: 1;
      visibility: visible;
    }

    .app-user-card {
      text-align: right;
      display: inline-block;
      min-width: 150px;
      position: absolute;
      top: $header-height;
      right: 4px;
      z-index: 10;
      font-size: 1rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity ease 0.5s;

      .card {
        align-items: center;
        background: $white;
        border-radius: 4px;
        border: none;

        h5 {
          /* Option 2 has hover */
          margin-top: 8px;
          font-family: "NotoSans-Regular";
          font-style: normal;
          color: $standard-black;
          font-size: 14px;
          background: rgba(4, 14, 40, 0.0411932);
          width: 87%;
          border-radius: 4px;
          text-align: center;
          padding-top: 7%;
          padding-bottom: 7%;
        }
      }

      .card-body {
        background: $component-header-background;
      }

      a {
        color: $bs-dark;
      }
    }

    .user-card {
      &.neo-card {
        &.card {
          display: inline-flex;
          border: none;
          background: none;

          .card-header {
            margin-top: 4px;
            border: none;
            background: url("../assets/img/down-black.png") no-repeat 85% 50%;

            .card-header-title {
              border: none;
              background: none;
              font-family: "NotoSans-Regular";
              font-style: normal;
              color: $standard-black;
              font-size: 14px;
            }

            .fa-angle-down:before {
              content: none;
            }

            .fa-angle-up:before {
              content: none;
            }
          }

          .card-body {
            align-items: center;
            background: $white;
            border-radius: 4px;
            border: none;
            padding-left: 25px;
            padding-right: 0px;
            padding-top: 0px;
            padding-bottom: 3px;

            h5 {
              /* Option 2 has hover */
              margin-top: 8px;
              font-family: "NotoSans-Regular";
              font-style: normal;
              color: $standard-black;
              font-size: 14px;
              background: rgba(4, 14, 40, 0.0411932);
              width: 87%;
              border-radius: 4px;
              text-align: center;
              padding-top: 7%;
              padding-bottom: 7%;
            }
          }
        }
      }
    }
  }
}

.member-display {
  /* Liberty member */
  width: 121px;
  height: 18px;
  margin-left: 10px;
  top: 1px;
  font-family: "NotoSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: $standard-black;

  img {
    margin-left: 8px;
    padding-left: 1px;
    margin-right: 5px;
  }

  i {
    float: "right";
    margin-top: 6px;
    margin-left: 1px;
  }
}

div.bread-crumb {
  display: inline;

  div.bread-crumb-level {
    display: inline;

    span.bread-crumb-seperator {
      padding: 0 10px;
    }

    a,
    span {
      display: inline-block;
    }
  }
}

.app-left-panel {
  position: fixed;
  width: $menu-width;
  z-index: 4;
  height: 100%;
  padding-top: 7px;
  background: $component-header-background;
  box-shadow:
    0 0 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-top: 0px;
  transition:
    width ease-in-out $menu-animation-speed,
    left ease-in-out $menu-animation-speed;
}

.app-content-area {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - #{$page-height});
}

.app-right-panel {
  margin-left: $menu-width;
  padding-top: $header-height;
  background: $main-background;
  transition: margin-left ease-in-out $menu-animation-speed;
}

.app-footer {
  flex-direction: column;
  font-family: "NotoSans-Regular";
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: $footer-height;
  text-align: center;
  background: $component-footer-background;
  color: $standard-black;
  overflow: hidden;

  .footer-text {
    margin-top: 0%;
    font-size: 12px;
    line-height: 16px;
  }

  .small-corp {
    text-align: center;
    margin: auto;
  }
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid $lightGrey;
}

@media (max-width: 768px) {
  .app-footer {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;

    .divider {
      display: flex;
      width: 100%;
    }

    .footer-text {
      flex-wrap: wrap;
      display: flex;
      width: 100%;

      span {
        border-top: none;
        padding: 2px 5px;
      }

      .back-to-t-and-c {
        width: 100%;
      }
    }
  }
}

.thin-sidebar {
  .app-left-panel {
    width: $menu-width-thin;
  }

  .app-right-panel {
    margin-left: $menu-width-thin;
  }
}

.sidebar-expanded {
  .app-header-panel {
    position: fixed;
  }

  .app-left-panel {
    width: $menu-width;
    left: auto;
  }
}

@media (min-width: 768px) {
  // Huge screens

  .constrain-width {
    max-width: (1920px - $menu-width);
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  div.container-xl {
    max-width: 1160px;
    padding: 0px 20px;
    margin: 0px -20px;
  }

  div.loader-container.page-loader {
    > div.loader-overlay {
      left: $menu-width;
    }
  }
}

@media (max-width: 992px) {

  // Smaller than 992px - menu will be hidden
  .app-header-panel {
    .mini-logo-display {
      height: 70%;
      margin-left: 1%;
      margin-right: 2%;

      &.liberty-icon-change-height {
        height: 50%;
      }
    }

    .app-header {
      .menu-anchor-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        height: 60px;
      }

      .menu-anchor {
        height: 60px;
      }

      .logo-and-user-container {
        height: 60px;
        display: flex;
      }

      .liberty-logo-container {
        height: 60px;
        margin-top: 15px;
        margin-right: 8px;
      }

      .user-roles-image-container {
        display: block;
        height: 60px;
      }

      .app-hamburger-container {
        display: block;
      }

      .mini-logo-display {
        display: block;
      }
    }

    .user-roles-image {
      float: right;
      margin-right: 15px;
      height: 60px;

      .btn-group {
        height: 60px;
        width: 50px;
        background: url("../assets/img/user-roles-mobile.png") no-repeat 98.2% 50%;

        @media (max-width: 1700px) {
          width: 40px;
        }

        .dropdown-toggle::after {
          display: none;
        }

        .dropdown-menu {
          width: 242px;
          background: $white;
          box-shadow:
            0px 0px 2px rgba(0, 0, 0, 0.03),
            0px 6px 10px rgba(0, 0, 0, 0.08);
          border-radius: 5px;
          border: none;
          --bs-dropdown-link-hover-bg: none;
          --bs-dropdown-link-active-color: #0d1325;
          --bs-dropdown-link-active-bg: none;
        }

        .btn {
          &.btn-primary {
            background: none;
            border: none;
            --bs-btn-color: none;
            --bs-btn-bg: none;
            --bs-btn-hover-bg: none;
            --bs-btn-active-color: none;
            --bs-btn-active-bg: none;
          }
        }
      }
    }
  }

  .app-user-icon {
    display: none;
  }

  .app-header-panel {
    left: 0%;
    background: #00164e;
    color: $white;

    .app-header {
      display: grid;
      grid-template-columns: 65px auto 50px;
      justify-content: stretch;
    }

    .welcome-tag {
      font-size: 18px;
      color: $white;
      padding-left: 0px;

      @media (max-width: 360px) {
        font-size: 14px;
      }
    }

    .app-hamburger-container {
      padding-left: 10px;
      width: $header-height;
      font-size: 24px;

      color: #aaaaaa;
      cursor: pointer;

      &:hover {
        color: $bs-primary;
      }

      i {
        display: flex;
      }
    }
  }

  .container {
    margin-left: auto;
  }
}

@include media-breakpoint-down(xxl) {
  div.container-xl {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 992px) {
  .thin-sidebar {
    .app-left-panel {
      width: $menu-width;
      left: -($menu-width + 3);
      overflow: hidden;
      overflow-y: scroll;
    }

    .app-right-panel {
      margin-left: 0;
    }
  }

  .sidebar-expanded {
    .app-left-panel {
      left: 0;
    }
  }
}

@include media-breakpoint-down(xs) {}

@media (min-width: 993px) and (max-width: 1194px) {
  .app-left-panel {
    width: 216px;
  }

  .sidebar ul .menu-item {
    width: 216px;
  }

  .app-header-panel {
    left: 216px;
  }

  .app-right-panel {
    margin-left: 221px;
  }
}

.background-card {
  position: center;
  margin: auto;
  width: 99%;
  top: 98px;
  min-height: 200px;
  height: max-content;
  background: $white;
  box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  border-radius: 5px;
  margin: 30px 15px 30px 5px;
  padding: 10px 12px 10px 12px;

  @media (max-width: 1500px) {
    width: 99%;
  }

  @media (max-width: 1000px) {
    width: 98%;
  }

  @media (max-width: 500px) {
    min-height: 350px;
    width: 98%;
    padding: 10px 12px 30px 12px;
    margin-right: 3px;
  }

  @media (max-width: 400px) {
    min-height: 0px;
    margin-right: 2px;
  }
}

.separating-line-max {
  border-bottom: 1px solid $greyLine;
  width: 100%;
  padding: 0;
}

.row {
  &.separating-line-padding {
    padding: 0;
  }
}

.separating-line {
  border-bottom: 1px solid $greyLine;
  width: 100%;
}

//TODO: Remove this once the <hr> has been implemented 
.pika-separating-line {
  border-bottom: 1px solid $greyLine;
}

.neo-pager {
  thead {
    display: none;
  }

  .pager-controls-container-bottom {
    text-align: inherit;
  }
}

.neo-pager-controls {
  .neo-pager-page-size {
    color: black;
    width: 182px;

    @media (min-width: 768px) and (max-width: 1194px) {
      width: 193px;
    }

    select.form-control {
      display: inline;
      width: auto;
      padding: 0;
      border-radius: 0px;
      border: none;
    }
  }

  .pagination {
    .page-item {
      &.active {
        .page-link {
          background-color: $azureBlue;
          color: white;
          border-radius: 50%;
          --bs-gradient: none;
        }
      }

      .page-link {
        border: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

@media (min-width: 1880px) {
  .container {
    max-width: 100%;
  }
}

.margin-x-unset {
  margin-left: unset !important;
  margin-right: unset !important;

  .col-md-12 {
    @media (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

//Customized scrollbar look and feel on all the pages

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.toast-container {
  padding: 10px;
  border-radius: 5px;
  position: fixed;
  top: 10px;
  right: 10px;

  font-family: "NotoSans-Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.3px;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }

  .toast-header {
    color: $white;
    justify-content: space-between;

    &.toast-header-success {
      background-color: $darkerGreen;
    }

    &.toast-header-warning {
      background-color: $amber;
    }

    &.toast-header-primary {
      background-color: $hoverBlue;
    }

    &.toast-header-danger {
      background-color: $warningRed;
    }

    .close {
      background-color: transparent;
      border: 0;
      padding: 0;
      opacity: 0.8;
      font-size: 1.5rem;
      color: white;
    }
  }

  .toast-body {
    color: #424757;
  }
}

#root > main > .loading-page {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #002B99;

  .loader-image {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .loader-overlay,
  .loader-inner {
    left: 0;
    top: 40%;
    background: none;
  }
}

.no-data-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: "NotoSans-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  background-color: #f2f2f2;
  padding: 1% 0 1% 0;

  .info-circle {
    width: 45px;
  }
}

//File upload documents section

.row {
  &.file-upload-documents {
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    display: inline-flex;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &.phone-remove-margin {
        margin-left: 0px;
      }
    }

    .add-margin-to-right {
      @media (max-width: 768px) {
        display: flex;
        justify-content: center;

        &.phone-justify-left {
          justify-content: left;
        }
      }

      .uploaded-document-info-block {
        border-radius: 4px;
        background: #eef5ff;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        width: 220px;
        margin-left: 12px;

        @media (max-width: 768px) {
          margin-left: 0px;
          margin-top: 5px;
        }

        &.add-margin-top {
          margin-top: 5px;
        }

        .uploaded-document-info-text {
          color: $azureBlue;
          font-family: "NotoSans-Bold";
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.429px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .file-name {
            max-width: 150px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .image-container {
            white-space: nowrap;
            display: flex;
          }

          .uploaded-document-info-image {
            margin-left: 10px;
            cursor: pointer;

            &.delete {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.display-asterisk {
  color: $red;
}

//Standard liberty dropdown
.auto-complete-dropdown {
  height: 45px;
  background: $shadowGrey;
  font-size: 16px;

  .react-select-bs__indicators {
    display: none;
  }

  .react-select-bs__value-container {
    background: url(../../App/assets/img/drop-down-arrow.png) no-repeat right;
    background-position-x: 97%;
    background-position-y: 50%;
    height: 45px;
    cursor: pointer;
  }

  .react-select-bs__single-value {
    width: 85%;
  }

  .react-select-bs__menu-list {
    color: $standard-black;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .react-select-bs__option:hover {
    background: rgba(4, 14, 40, 0.0411932);
    cursor: pointer;
  }

  .react-select-row.selected {
    background: rgba(4, 14, 40, 0.0411932);
    color: $standard-black;
  }

  .react-select-row.focused {
    background: rgba(4, 14, 40, 0.0411932);
    color: $standard-black;
  }

  .react-select-bs__control--menu-is-open {
    .react-select-bs__value-container {
      background: url("../../App/assets/img/drop-up-arrow.png") no-repeat right;
      background-position-x: 97%;
      background-position-y: 50%;
    }
  }
}

.neo-datepicker-container {
  .form-control {
    font-family: "NotoSans-Regular";
    box-sizing: border-box;
    background: $shadowGrey;
    border: 1px solid #afb3c2;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    background:
      url(../../../src/App/assets/img/calendar.png) no-repeat 96% 50%,
      $shadowGrey;
  }
}