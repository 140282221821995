@import "../Variables.scss";

.neo-tooltip.ui-tooltip {
  height: auto;
  width: 160px;

  @media (max-width: 410px) {
    width: 100px;
  }

  @media (max-width: 410px) {
    width: 100px;
  }
}

.background-card-vas {
  position: center;
  top: 98px;
  min-height: fit-content;
  height: max-content;
  background: $white;
  box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
  border-radius: 5px;
  margin: 30px 15px 20px 15px;

  @media (min-width: 800px) and (max-width: 1200px) {
    margin-top: 30px;
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    margin: 25px 15px 5px 5px;
  }
}

.background-card-vas {
  padding: 0px 0px 30px 0px;

  .save-portfolio-link-vas {
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $azureBlue;
    text-decoration: none;
    margin-left: 21px;
    margin-top: 0px;
    margin-bottom: 23px;

    a {
      margin-top: 15px;

      &:hover {
        cursor: pointer;
        color: $azureBlue;
      }
    }

    @media (max-width: 768px) {
      color: $azureBlue;
      display: block;
      text-decoration: none;
      text-align: center;
      margin-left: 0px;
      margin-top: 4px;
    }
  }

  &.remove-padding {
    padding: 0px;
  }

  .card-heading {
    font-family: "NotoSans-Regular";
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: $standard-black;
    margin: 15px 0px 10px 0px;
    padding-left: 30px;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 18px;
      padding-right: 10px;
      padding-left: 25px;
      padding-top: 0px;
    }

    p {
      color: $black;
      font-family: "NotoSans-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.2px;
      padding-top: 10px;
      margin-bottom: 0px;
      padding-right: 10px;

      @media (max-width: 768px) {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .align-liberty-link {
      padding-bottom: 5px;
    }
  }

  .row {
    &.vas-row {
      padding: 0px;
      margin: 0px;

      .divider-vas {
        border: 1px solid $greyLine;

        @media (min-width: 768px) and (max-width: 1200px) {
          width: 100%;
        }
      }
    }
  }

  .cards-cu {
    position: center;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    height: max-content;
    background: $white;
    margin-bottom: 20px;
    padding: 0px 0px 10px 0px;

    .card-header {
      background: $white;
      border: none;
    }

    .fa-angle-down:before {
      color: transparent;
      appearance: none;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("../../assets/img/drop-down-arrow.png");
      background-repeat: no-repeat;
    }

    .fa-angle-up:before {
      color: transparent;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("../../assets/img/drop-up-arrow.png");
      background-repeat: no-repeat;
    }

    .card-header-right-section {
      padding-top: 5px;
    }
  }

  .neo-card {
    &.card {
      border: none;

      &.cards-headings-cu-vas {
        &.collapsed {
          padding: 0px;

          .card-header {
            color: $standard-black;
            font-family: "NotoSans-Regular";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 31px;
            border-radius: 4px 4px 0px 0px;
            height: 60px;
            width: 100%;
            background-repeat: no-repeat;
            background-position: right;
            background-position-x: 99%;
            background-size: 23px;
            cursor: pointer;
            padding-right: 30px;

            @media (max-width: 768px) {
              height: 58px;
              background-size: 16px;
              background-position-x: 95%;
            }

            .card-header-title {
              pointer-events: none;

              .align-title {
                display: flex;
                align-items: center;
              }

              @media (max-width: 768px) {
                font-size: 14px;
                line-height: 16px;
              }

              .img-class {
                margin-left: 10px;
                margin-right: 20px;
                width: 28px;
                height: 28px;
              }

              i {
                font-size: 1.5rem;
                width: 30px;
              }
            }
          }
        }
      }
    }
  }

  .neo-card.card.cards-cu.cards-headings-cu-vas:not(.collapsed) {
    box-shadow: none;

    .card-header {
      color: $standard-black;
      font-family: "NotoSans-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 31px;
      border: 2px solid $azureBlue;
      border-radius: 4px 4px 0px 0px;
      height: 60px;
      width: 100%;
      cursor: pointer;
      padding-right: 30px;

      .card-header-title {
        pointer-events: none;

        .align-title {
          display: flex;
          align-items: center;
        }

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 16px;
        }

        .img-class {
          margin-left: 10px;
          margin-right: 20px;
          width: 28px;
          height: 28px;
        }

        i {
          font-size: 1.5rem;
        }
      }

      @media (max-width: 768px) {
        height: 58px;
      }
    }
  }

  .cards-headings-cu-vas {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: $standard-black;
    background: $white;
    margin: 5px 20px 10px 20px;

    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 16.34px;
      margin: 0px 15px 20px 15px;
    }

    p {
      margin-bottom: 0px;
      color: $black;
      font-family: "NotoSans-Regular";
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.2px;
    }
  }

  .icon-cu {
    width: 32px;
    height: 32px;
    float: left;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $purple;
    color: $white;
    font-size: 12px;
    font-family: "NotoSans-Regular";
    font-weight: 400;
    border-radius: 50%;
  }

  .information-financial-advisers-cards-cu {
    width: 265px;
    height: 362px;
    background: $white;
    box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
    border-radius: 4px;
    padding: 18px;
  }

  .row > .negate-col-information-financial-advisers-card-margin {
    width: 265px;
    margin: 20px 16px 10px 30px;
    padding-left: 0px;

    @media (max-width: 768px) {
      padding-left: 0px;
      margin-inline: auto;
    }
  }

  .information-heading-cards-cu {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $standard-black;
    margin: 0px 0px 10px 40px;
    text-align: left;
  }

  .information-heading-financial-advisers-cards-cu-vas {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: $standard-black;
    text-align: left;
    height: 65px;
    margin-left: 37px;

    &.financial-advisers-cards {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .information-available-cards-cu {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #424757;
    margin: 0px 0px 10px 40px;
  }

  .information-description-cards-cu {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #424757;
    margin-top: 15px;
  }

  .information-detail-contact-information-cards-cu {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    line-height: 14px;
    color: $standard-black;
    margin-bottom: 10px;
    height: 10px;

    a {
      color: $hoverBlue;
      text-decoration: none;
    }
  }

  .information-detail-cards-cu {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    line-height: 14px;
    color: $standard-black;
    margin-bottom: 10px;

    a {
      color: $hoverBlue;
      text-decoration: none;
    }
  }

  .information-detail-adress-cards-cu {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    line-height: 18px;
    color: $standard-black;
    margin-bottom: 10px;
  }

  .information-complain-detail-cards-cu {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    line-height: 18px;
    color: $standard-black;
    margin-bottom: 5px;

    a {
      color: $hoverBlue;
      text-decoration: none;
    }
  }

  .add-margin-for-vas-btn {
    margin-left: 16px;
    margin-top: 5px;
    display: flex;

    .vas-download-btn {
      letter-spacing: normal;

      .neo-icon {
        display: flex;
        margin-top: 4px;
        margin-right: 8px;
      }
    }

    @media(max-width: 768px) {
      justify-content: center;
      margin-left: 0px;
    }
  }

  .download-icon-vas {
    margin-right: 3px;
    margin-top: 2px;
    height: 24px;
    width: 24px;

    @media (max-width: 768px) {
      margin-right: 10px;
      margin-top: 4px;
    }
  }

  .qr-code-vas {
    margin-left: 0px;
    margin-bottom: 20px;

    @media (max-width: 1500px) {
      margin-bottom: 0px;
    }

    @media (max-width: 768px) {
      margin-left: 15px;
    }
  }

  .notes-disclaimer-block {
    background: $main-background;
    border-radius: 6px;
    margin: 15px 17px 15px 17px;
    padding: 10px;
  }

  .notes-disclaimer-block-text-heading-vas {
    font-family: "NotoSans-SemiBold";
    font-weight: 500;
    font-size: 14px;
    line-height: 35px;
    letter-spacing: 0.2px;
    color: $black;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 14px;
      line-height: 30px;
    }
  }

  .notes-disclaimer-block-text-vas {
    font-family: "NotoSans-Regular";
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: $black;

    @media (max-width: 500px) {
      font-weight: 400;
      line-height: 22px;
    }
  }
}

.telephone-number-vas {
  color: $hoverBlue;
  text-decoration: none;
}

.reference-vas {
  color: $hoverBlue;
}

.hidden-vas {
  @media (min-width: 1000px) {
    display: none;
  }
}

.hidden-newline-vas {
  @media (min-width: 1200px) {
    display: none;
  }
}

.hidden-vertical-bar-vas {
  @media (max-width: 1200px) {
    display: none;
  }
}

.modal-container {
  .modal-dialog {
    &.modal-lg {
      &.vas-template-modal {
        .modal-content {
          max-width: 100%;

          .modal-body {
            text-align: left;
          }

          .modal-footer {
            width: 91%;
          }
        }
      }
    }
  }
}

.vas-template-modal,
.maintenance-view {
  .modal-header {
    color: #00164e;
  }

  .modal-content {
    .modal-body {
      padding: 16px;

      .form-group {
        margin-bottom: 20px;
      }

      .form-group label {
        color: black;
      }
    }
  }

  button.close {
    background: none;
    border: none;

    span {
      font-size: 24px;
    }
  }

  table.table > thead th {
    background: $white;
    color: $standard-black;
  }

  table.table.table-liberty {
    border-collapse: separate;
    border-spacing: 0 15px;

    thead {

      td,
      th {
        border: none;
      }
    }

    tbody tr {
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      margin-bottom: 10px;
      border: none;
      border-radius: 4px;
    }

    tbody td {
      padding: 6px 6px 6px 24px;
    }
  }

  .form-control {
    background: $shadowGrey;
    border: 1px solid #8b92a7;
    border-radius: 4px;
  }

  .background-card {
    padding-right: 30px;
  }

  .vas-cards-header-alignment {
    display: flex;
    align-items: center;
    justify-content: normal;

    .cards-headers-vas {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 16px;
      line-height: 31px;
      color: $standard-black;
      padding-top: 14px;

      @media (max-width: 768px) {
        line-height: 22px;
      }

      @media (max-width: 400px) {
        p {
          margin-bottom: 0px;
        }
      }
    }
  }

  .line-cu {
    width: 100%;
    fill: $lightPurpleGrey;
    border-bottom: 1px solid $lightPurpleGrey;
    margin: 15px 0px 15px 0px;
  }

  .btn {
    &.btn-danger {
      color: var(--bs-btn-border-color);
      border-radius: 4px;
      padding: 6px 20px 6px 20px;
      background: $white;
      border: none;
      --bs-btn-active-bg: $white;
      --bs-btn-active-color: var(--bs-btn-border-color);

      &:hover {
        color: var(--bs-btn-border-color);
        background: $white;
        border: none;
      }
    }

    &.btn-primary {
      background: $azureBlue;
      color: $white;
      font-family: "NotoSans-Light";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      border-radius: 4px;
      padding: 13px 16px 15px 16px;
      width: 176px;
      height: 48px;
      line-height: 20px;
      --bs-btn-active-bg: $azureBlue;
      --bs-btn-active-color: $white;

      &:hover {
        background: $azureBlue;
        color: $white;
      }

      &.btn-sm {
        padding: 13px 16px 15px 16px;
        width: 100px;
        height: 32px;
        font-size: 14px;
      }
    }

    &.btn-link {
      text-decoration: none;
      font-family: "NotoSans-Light";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      width: 34%;
      letter-spacing: 0.133333px;

      &.btn {
        color: #0033aa;
        font-family: "NotoSans-Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.133333px;
      }
    }
  }
}