@import "../Variables.scss";

.background-card {
  &.body-card-complaints-dropdown {
    margin: 40px 15px 20px 5px;
    padding-left: 26px;
    padding-top: 10px;
    min-height: 180px;

    @media (max-width: 1200px) {
      margin: 30px 15px 20px 5px;
    }

    @media (max-width: 768px) {
      padding-left: 16px;
      padding-right: 16px;
      min-height: fit-content;
      margin: 20px 15px 5px 5px;
      padding-bottom: 15px;
    }

    @media (max-width: 360px) {
      padding-left: 15px;
    }
  }

  &.body-card-complaints {
    margin: 20px 15px 30px 5px;
    padding: 10px 0px 20px 12px;

    &.body-card-complaints-divider {
      padding: 10px 5px 0px 12px;
    }

    @media (max-width: 768px) {
      margin: 10px 15px 30px 5px;
    }

    .complaints-css {
      margin-right: 0.5%;

      @media (max-width: 768px) {
        margin-right: 0%;
      }

      .neo-card {
        &.card {
          &.member-details-card-bfs {
            box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
            border-left: 7px solid $azureBlue;
            margin-top: 25px;
            width: 99%;

            @media (max-width: 500px) {
              margin-top: 20px;
            }

            .right-arrow {
              height: 24px;
              width: 24px;
              background-color: transparent;
              appearance: none;
              background-image: url("../../assets/img/down-right-black.png");
              background-repeat: no-repeat;
              border: none;
              float: right;
              margin-top: 7px;
            }

            .card-header {
              background: none;
              border-bottom: none;
              display: block;
              padding-right: 8px;

              @media (max-width: 1200px) {
                padding-right: 2px;
              }

              @media (max-width: 500px) {
                padding-right: 6px;
              }
            }

            .card-body {
              padding: 0px 16px 16px 16px;

              @media (max-width: 1200px) {
                padding-right: 6px;
              }
            }
          }
        }
      }

      .member-details-card-bfs {
        background: $white;
        box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
        border-radius: 5px;
        border: 4px $standard-black;
      }

      .member-card-information-bfs {
        font-family: "NotoSans-SemiBold";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 27px;
        color: $standard-black;
        margin-top: 8px;

        @media (max-width: 1200px) {
          font-size: 12px;
        }

        @media (max-width: 500px) {
          font-size: 14px;
        }

        .complaint-card-header {
          font-family: "NotoSans-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 31px;
          color: #424757;
        }
      }

      .scheme-information-header {
        background: none;
        box-shadow: none;

        .scheme-header-card {
          &.card {
            @media (max-width: 768px) {
              width: 93%;
            }
          }
        }

        &.background-card {
          margin: 0px;
          padding: 0px 20px 0px 10px;

          .padding-top-scheme-header {
            margin: 5px 0% 0% 1%;
            padding: 0px;
          }
        }
      }

      .membership-number-control {
        &.col-md-12 {
          width: 88%;
        }
      }

      .align-buttons-desktop {
        &.row {
          display: flex;
          align-items: center;
        }

        .divider-complain-buttons {
          width: 99.8%;
          fill: $lightPurpleGrey;
          border-bottom: 1px solid $lightPurpleGrey;
          margin: 1% 0% 20px 0%;

          @media (min-width: 768px) and (max-width: 1194px) {
            width: 99.2%;
          }
        }
      }

      .align-buttons-mobile {
        &.row {
          display: none;
        }
      }

      .align-radio {
        color: $standard-black;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        padding-top: 1%;

        label {
          flex: 1 1 auto;
        }
      }

      .align-communication-method {
        padding-top: 0px;
      }

      .custom-control-input {
        margin-right: 4%;
        width: 20px;
        height: 20px;
      }

      .custom-control-label {
        font-size: 16px;
        font-weight: 500;
        font-style: "NotoSans-Medium";
      }

      .custom-control-inline {
        width: 31%;
        display: flex;
        align-items: center;
      }

      .custom-control-inline {
        input[type="radio"] {
          accent-color: $azureBlue;
        }
      }

      .phone-control-col {
        &.col-md-10 {
          padding-left: 0px;
          padding-right: 0px;

          @media (min-width: 768px) and (max-width: 1194px) {
            padding-left: 13px;
            padding-right: 0px;
          }
        }
      }

      .phone-control {
        label {
          display: none;
        }

        .form-control:disabled {
          height: 48px;
          box-sizing: border-box;
          background: $shadowGrey;
          border: 1px solid $darkShadowGrey;
          border-radius: 4px;
          font-family: "NotoSans-Regular";
          color: $standard-black;
        }
      }

      .form-group {
        &.padding-fix-complaint-box {
          padding-right: 28px;

          @media (max-width: 1200px) {
            padding-right: 26px;
          }
        }

        .query-control {
          label {
            display: none;
          }

          .form-control {
            font-family: "NotoSans-Regular";
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #afb3c2;
          }
        }
      }

      .upload-card {
        &.card {
          background: $white;
          border-radius: 4px;
          border: none;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
          margin-right: 15px;

          @media (max-width: 768px) {
            margin-right: 14px;
          }
        }
      }

      .form-group-email {
        &.col-12 {
          margin-top: 0%;

          @media (max-width: 768px) {
            margin-top: 5%;
          }

          @media (max-width: 450px) {
            margin-top: 7%;
          }
        }
      }

      .email-control {
        .form-control {
          height: 48px;
          font-family: "NotoSans-Regular";
          box-sizing: border-box;
          border: 1px solid #afb3c2;
          border-radius: 4px;
          color: $standard-black;
          width: 99%;
        }
      }

      .tel-control-complaint {
        width: 100%;

        .form-control {
          height: 48px;
          font-family: "NotoSans-Regular";
          box-sizing: border-box;
          border: 1px solid #afb3c2;
          border-radius: 4px;
          color: $standard-black;
        }
      }

      .align-link-end {
        display: none;
      }

      .card-query {
        width: 99%;
        background: $white;
        margin-left: 5px;
        padding-left: 27px;

        @media (max-width: 1500px) {
          width: 99%;
          margin-left: 0px;
        }

        @media (max-width: 1000px) {
          width: 98%;
          margin-left: 5px;
        }

        @media (max-width: 500px) {
          width: 98%;
        }

        &.card {
          height: auto;
          border-radius: 5px;
          margin-bottom: 5%;
          border: none;
          box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
        }
      }

      .form-label {
        color: $standard-black;

        @media (max-width: 400px) {
          margin-right: 10px;
        }
      }

      .align-buttons {
        padding-right: 2%;

        .btn-continue {
          width: 23%;
          margin-left: 2%;
        }
      }

      .file-drop-area {
        background: #deecff;
        border-radius: 4px;
        display: flex;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        margin: 1%;

        @media (max-width: 768px) {
          margin: 3px 3px 15px 3px;
        }

        &.neo-file-drop {
          border: 2px dashed $azureBlue;
          margin-right: 12px;
          margin-left: 12px;
        }
      }

      .file-upload-label {
        font-family: "NotoSans-Medium";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $azureBlue;
        justify-content: center;
        align-items: center;
        align-content: center;
      }

      .grey-upload-box {
        background: #deecff;
        border-radius: 4px;
        font-family: "NotoSans-Regular";
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $standard-black;
        margin: 10px;
        padding: 10px;
        width: 20%;

        @media (max-width: 768px) {
          padding: 10px 10px 10px 4px;
        }

        .grey-upload-box-text {
          .float-right {
            float: right;

            &.hover {
              cursor: pointer;
            }
          }

          &.floar-right {
            float: right;
          }
        }
      }

      /* Overriding React Dropdown Style Classes */

      .react-select-bs__indicators {
        display: none;

        &.css-1hb7zxy-IndicatorsContainer {
          display: none;
        }
      }

      .react-select-bs__menu-list {
        color: black;
      }

      .react-select-bs__value-container--has-value {
        background: url(../../assets/img/drop-down-arrow.png) no-repeat right;
        background-position-y: 50%;
        background-position-x: 97%;
        height: 46px;
      }

      .react-select-bs__option {
        background: none;

        &.react-select-bs__option--is-focused {
          background: none;
        }

        &.react-select-bs__option--is-selected {
          background: rgba(4, 14, 40, 0.0411932);
          color: black;
        }
      }

      .react-select-bs {
        .css-yk16xz-control {
          font-family: "NotoSans-Regular";
          box-sizing: border-box;
          background: $shadowGrey;
          border: 1px solid #afb3c2;
          border-radius: 4px;
        }
      }

      @media (min-width: 768px) and (max-width: 1194px) {
        .custom-control-inline {
          width: 44%;
        }

        .country-dropdown {
          &.col-md-2 {
            padding-right: 0px;
          }
        }

        .align-link-end {
          display: none;
        }

        .align-buttons {
          .btn-cancel {
            width: 35%;
          }

          .btn-continue {
            width: 35%;
          }
        }
      }

      @media (max-width: 768px) {
        .align-buttons-desktop {
          &.row {
            display: none;
          }
        }

        .align-buttons-mobile {
          &.row {
            display: flex;
          }
        }

        .neo-card.card .card-header {
          height: 46px;
          width: 100%;
        }

        .neo-card.card .card-header:not(.collapsed) {
          height: 46px;
        }

        .align-communication-method {
          padding-top: 30px;
        }

        .dropdown-box {
          .dropdown-content {
            font-size: 12px;
          }
        }

        .custom-control-inline {
          width: 50%;
        }

        .tel-control-complaint {
          .form-control {
            width: 100%;
            height: 48px;
          }
        }

        .css-g1d714-ValueContainer {
          height: 46px;
        }

        .phone-control-col {
          &.col-md-10 {
            padding-right: 13px;
          }
        }

        .phone-control {
          height: 40px;
        }

        .email-control {
          height: 40px;
        }

        .browse-button {
          &.btn {
            text-transform: capitalize;
          }
        }

        .membership-number-control {
          &.col-md-12 {
            width: 100%;
          }
        }

        .country-dropdown {
          &.col-2 {
            width: 24%;

            @media (max-width: 400px) {
              width: 25%;
              margin: none;
              padding-right: 5px;
            }
          }
        }

        .file-upload-label {
          display: none;
        }

        .col-12 {
          &.padding-fix-complaint {
            padding-top: 2%;
          }

          &.padding-fix-complaint-box {
            padding-top: 4%;

            @media (max-width: 768px) {
              padding-top: 1%;
              padding-right: 26px;
            }
          }
        }

        .align-link {
          display: none;
        }

        .align-buttons {
          display: flex;
          flex-direction: column;

          .btn-cancel {
            margin: 2%;
            width: 90%;
            height: 40px;
          }

          .btn-continue {
            margin: 2%;
            width: 90%;
            height: 40px;
          }
        }

        .align-link-end {
          display: flex;
          justify-content: center;
          padding-left: 0px;
        }
      }

      .row {
        .divider-mobile {
          @media (max-width: 768px) {
            width: 97%;
            border-bottom: 0.5px solid $greyLine;
            display: inline-block;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.card-schem {
  width: auto;
  background: $white;

  &.card {
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border: none;
  }
}

.show-complain {
  display: block;

  .complaint-contact-us {
    padding: 10px 12px 10px 4px;

    .shadowbox-cu {
      margin-right: 5px;
    }
  }
}

.react-select-bs__option {
  background: none;

  &.react-select-bs__option--is-focused {
    background: none;
  }

  &.react-select-bs__option--is-selected {
    background: rgba(4, 14, 40, 0.0411932);
    color: black;
  }
}

.hide-complain {
  display: none;
}

.btn-row {
  justify-content: normal;
}



.align-contact-complain-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border: 2px solid $azureBlue;
  width: 99%;
  border-radius: 4px;
  margin-left: 0.8%;

  &.col-md-6 {
    width: fit-content;
    padding-left: 0%;
    padding-right: 0%;
  }

  .btn-contact {
    border: none;
    border-radius: 0px;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.23px;

    &.btn {
      background-color: $azureBlue;
      color: $white;
      width: 185px;
      height: 40px;
      --bs-gradient: none;
    }

    &.btn-primary {
      --bs-btn-hover-bg: $azureBlue;
      --bs-btn-hover-color: $white;
      --bs-btn-active-color: $white;
      --bs-btn-active-bg: $azureBlue;
    }
  }

  .btn-complain {
    box-sizing: border-box;
    border-radius: 0px;
    width: auto;
    font-family: "NotoSans-Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 1.23px;

    &.btn {
      background-color: $white;
      color: $azureBlue;
      border: none;
      width: 185px;
      height: 40px;
      --bs-gradient: none;
    }

    &.btn-primary {
      --bs-btn-hover-bg: $white;
      --bs-btn-hover-color: $azureBlue;
    }
  }
}

.card-header-complaint {
  font-family: "NotoSans-Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: $standard-black;
}

.selected-value {
  font-weight: 500;
  font-size: 16px;
  background: rgba(4, 14, 40, 0.0411932);
}

.card-header-query {
  font-family: "NotoSans-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 35px;
  margin-bottom: 0px;
  letter-spacing: 0.2px;
  color: $standard-black;
}

@media (min-width: 768px) and (max-width: 1194px) {
  .divider-complain {
    width: 97%;
    margin-left: 0.5%;

    @media (max-width: 1200px) {
      margin-left: 0px;
    }
  }

  .align-contact-complain-buttons {
    .btn-contact {
      &.btn {
        width: 171px;
      }
    }

    .btn-complain {
      &.btn {
        width: 171px;
      }
    }
  }
}

@media (max-width: 768px) {
  .btn-row {
    justify-content: center;
  }

  .align-contact-complain-buttons {
    margin-left: 0%;

    .btn-complain {
      font-size: 15px;

      &.btn {
        width: 175px;
      }
    }

    .btn-contact {
      font-size: 15px;

      &.btn {
        width: 175px;
      }
    }
  }
}

@media (max-width: 400px) {
  .btn-row {
    justify-content: center;
  }

  .align-contact-complain-buttons {
    margin-left: 0%;

    .btn-complain {
      font-size: 14px;

      &.btn {
        width: 149.81px;
      }
    }

    .btn-contact {
      font-size: 14px;

      &.btn {
        width: 149.81px;
      }
    }
  }
}

//////////////////////////////////complaints modal ////////////////////////////////////////////////////////////////
.complaints-css {
  .add-padding-to-background {
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: 1500px) {
      padding-right: 9px;
    }

    @media (max-width: 1200px) {
      padding-left: 0px;
      padding-right: 6px;
    }

    @media (max-width: 768px) {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 10px;
    }
  }
}