@import "../../App/Styles/Variables.scss";

.claims {
  .header-background-card {
    background: $white;
    box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
    border-radius: 5px;
    min-height: 120px;
    height: auto;
    margin: 30px 5px 0px 5px;
    width: 99%;
    border-left: 7px solid $azureBlue;
    padding: 25px;

    @media (max-width: 768px) {
      width: 98%;
    }
  }

  //TODO: Remove once proper separating line has been implemented
  .separating-line {
    &.width {
      width: 97%;
      margin-left: 20px;
    }

    &.width-margin {
      width: 97%;

      @media (max-width: 768px) {
        width: 93%;
      }
    }

    &.width-margin-bottom {
      width: 97%;
      margin: 20px 0px 10px 20px;

      @media (max-width: 768px) {
        width: 93%;
        margin: 20px 0px 10px 10px;
      }
    }
  }

  .header-background-card-title {
    font-family: "NotoSans-Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.171429px;
    color: $azureBlue;
    margin-bottom: 10px;
  }

  .header-background-card-text {
    font-family: "NotoSans-Regular";
    font-size: 14px;
    line-height: 20px;
    color: $standard-black;

    .bold {
      font-family: "NotoSans-SemiBold";
    }
  }

  .background-card {
    &.withdrawal-claim-view {
      margin-top: 20px;
      //TODO: Remove once background card is fixed
      padding-left: 0px;
      padding-right: 0px;

      @media (max-width: 768px) {
        margin-top: 15px;
      }
    }
  }

  .claims-title {
    font-family: "NotoSans-SemiBold";
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $standard-black;
  }

  .scheme-dropdown-label {
    margin-top: 0px;
    font-family: "NotoSans-Regular";
    font-size: 14px;
    color: $standard-black;
  }

  .steps-progress {
    font-family: "NotoSans-Regular";
    font-weight: 400;
    font-size: 14px;
    color: $standard-black;

    .step-background-card {
      background: $white;
      box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      min-height: 225px;
      max-height: max-content;
      margin: 20px 5px 20px 5px;
      padding: 20px 0px;

      @media (max-width: 768px) {
        margin: 0px 5px 15px 5px;
      }
    }

    .add-new-beneficiaries {
      &.remove-background-shadow {
        box-shadow: none;
      }
    }

    .steps-header {
      display: flex;
      justify-content: space-between;
    }

    .steps-header-text {
      font-family: "NotoSans-SemiBold";
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 0.171429px;
      color: $standard-black;
    }

    .steps-sub-header {
      font-size: 16px;
      line-height: 27px;
    }

    .form-text {
      &.right-side {
        &.form-group {
          margin: 20px 30px 20px 0px;

          @media (max-width: 768px) {
            margin: 20px 15px 5px 15px;
          }

          &.remove-margin-bottom {
            margin-bottom: 0px;
          }
        }
      }

      &.form-group {
        margin: 20px 0px 20px 30px;

        @media (max-width: 768px) {
          margin: 20px 15px 5px 15px;
        }

        label {
          font-size: 12px;
          line-height: 20px;
        }

        .form-control {
          height: 48px;
          background: $white;
          border: 1px solid $lightGreyishBlue;
          border-radius: 4px;
          line-height: 19px;
          color: $greyishBlue;
          text-align: left;
          box-shadow: none;
          cursor: default;
        }
      }

      &.change-color {
        &.form-group {
          .form-control {
            background: $white;
            border: 1px solid $lightGrey;
            border-radius: 4px;
            color: $standard-black;
            cursor: text;

            &:focus {
              box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
            }
          }
        }
      }
    }

    .custom-control-input {
      margin-right: 4%;
      width: 25px;
      height: 25px;
    }

    .custom-control-label {
      font-size: 16px;
      font-weight: 500;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }

    .custom-control-inline {
      width: 16rem;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        width: 50%;
      }
    }

    .custom-control-inline {
      input[type="radio"] {
        accent-color: $azureBlue;
      }
    }

    .align-radio {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      padding-top: 20px;

      &.withdrawal-details {
        padding-top: 0px;
      }

      label {
        flex: 1 1 auto;
      }
    }

    .employer-member-profile-dropdown {
      .react-select-bs__value-container {
        background:
          url("../../App/assets/img/dropdown-blue.png") no-repeat 97% 50%;

        &.react-select-bs__control--menu-is-open {
          background: url("../../App/assets/img/drop-up-arrow.png") no-repeat 97% 50%;
        }
      }
    }

    .country-codes-dropdown {
      width: auto;

      .react-select-bs__value-container--has-value {
        background: url("../../App/assets/img/arrow-down-solid.png") no-repeat right;
        height: 48px;
      }

      .react-select-bs__indicators {
        display: none;

        &.css-1hb7zxy-IndicatorsContainer {
          display: none;
        }
      }
    }

    .country-dropdown-row {
      &.row {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @media (max-width: 768px) {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          align-items: stretch;
          width: 100%;
          padding-left: 5px;
          padding-right: 0px;
        }
      }

      .input-group {
        height: 48px;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid $darkGreyishBlue;

        .form-control {
          border-top: none;
          border-bottom: none;
          border-left: none;
          background: $shadowGrey;
        }

        .input-group-text {
          background-color: $shadowGrey;
          border: none;
          border-left: 1px solid $darkGreyishBlue;

          .form-check-input {
            width: 1.5em;
            height: 1.5em;

            &:focus {
              box-shadow: none;
              border-color: $darkGreyishBlue;
            }
          }

          .form-check-input:checked {
            background: url(../../App/assets/img/checked.png);
            background-color: none;
            border-color: none;
            background-color: $azureBlue;
            padding: 0px;
          }
        }
      }
    }

    .align-cell-label {
      &.form-label {
        @media (max-width: 768px) {
          margin-top: 15px;
        }
      }
    }

    .header-of-step {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin-left: 25px;

      @media (max-width: 768px) {
        margin: 0 20px 0 20px;
      }
    }

    .documentation-heading {
      @media (max-width: 768px) {
        margin-left: 15px;
      }
    }

    .how-it-works-contact-card-container {
      display: flex;
      margin: 20px 25px 30px 25px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        margin: 20px auto;
      }
    }

    .card-container {
      margin: 25px 25px;

      @media (max-width: 768px) {
        margin: 25px 20px;
      }

      .neo-card {
        &.card {
          border: none;

          &.withdrawal-claims-cards {
            font-family: "NotoSans-Regular";
            color: $standard-black;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.2px;

            &.collapsed {
              padding: 0px;

              .card-header {
                font-size: 16px;
                font-weight: 600;
                line-height: 31px;
                border-radius: 4px 4px 0px 0px;
                height: 60px;
                cursor: pointer;
                border: none;
                box-shadow: 0px 3px 7px rgba(139, 146, 167, 0.5);
                background: $white;

                @media (max-width: 768px) {
                  height: 65px;
                }

                .card-header-title {
                  pointer-events: none;

                  .align-title {
                    display: flex;
                    align-items: center;
                  }

                  @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 13px;
                  }

                  .img-class {
                    margin-right: 15px;
                  }

                  i {
                    font-size: 1.5rem;
                  }
                }

                .card-expand-indicator {
                  &.fa {
                    &.fa-fw {
                      &.fa-angle-down {
                        color: transparent;
                        appearance: none;
                        display: block;
                        width: 24px;
                        height: 24px;
                        background-image: url("../../App/assets/img/drop-down-arrow.png");
                        background-repeat: no-repeat;
                        margin-top: 10px;
                      }

                      &.fa-angle-up {
                        color: transparent;
                        display: block;
                        width: 24px;
                        height: 24px;
                        background-image: url("../../App/assets/img/drop-up-arrow.png");
                        background-repeat: no-repeat;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .neo-card.card.withdrawal-claims-cards:not(.collapsed) {
        box-shadow: none;

        .card-header {
          font-size: 16px;
          font-weight: 600;
          line-height: 31px;
          border: 2px solid $azureBlue;
          border-radius: 4px 4px 0px 0px;
          height: 60px;
          width: 100%;
          cursor: pointer;
          background: $white;

          @media (max-width: 768px) {
            height: 65px;
          }

          .card-header-title {
            pointer-events: none;

            .align-title {
              display: flex;
              align-items: center;
            }

            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 13px;
            }

            .img-class {
              margin-right: 15px;
            }

            i {
              font-size: 1.5rem;
            }
          }

          .card-expand-indicator {
            &.fa {
              &.fa-fw {
                &.fa-angle-up {
                  color: transparent;
                  display: block;
                  width: 24px;
                  height: 24px;
                  background-image: url("../../App/assets/img/drop-up-arrow.png");
                  background-repeat: no-repeat;
                }
              }
            }
          }
        }
      }
    }

    .preservation-and-transfer {
      .preservation-card {
        background: $white;
        box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
        border-radius: 5px;
        border-left: 7px solid $azureBlue;
        margin: 25px;
        padding: 15px;
        height: max-content;

        @media (max-width: 768px) {
          margin: 0px 15px 15px 15px;
        }

        @media (max-width: 400px) {
          padding: 15px 5px;
        }

        .preservation-card-heading {
          font-family: "NotoSans-Bold";
          font-weight: 700;
          font-size: 24px;
          line-height: 31px;

          @media (max-width: 768px) {
            font-size: 22px;
          }

          .align-link {
            @media (max-width: 768px) {
              text-align: center;
              margin-top: 5px;
            }
          }

          .amount {
            color: $hoverBlue;
          }
        }

        .preservation-card-sub-heading {
          font-family: "NotoSans-SemiBold";
          font-weight: 600;
          line-height: 27px;
          margin-top: 5px;

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }

      .preservation-heading {
        margin: 0px 0px 10px 25px;

        @media (max-width: 768px) {
          margin-left: 15px;
        }

        &.financial-margin-top {
          margin-top: 50px;
        }

        &.semi-bold {
          font-family: "NotoSans-SemiBold";
        }
      }

      .preservation-financial-text {
        line-height: 20px;
        margin: 30px 0px 15px 25px;

        @media (max-width: 768px) {
          margin: 30px 0px 15px 15px;
        }
      }

      .preservation-table {
        margin: 25px;

        @media (max-width: 768px) {
          margin: 25px 15px;
        }

        .border {
          border: 1px solid $greyLine;
          border-radius: 5px;

          .table {
            &.neo-grid {
              &.table-bordered {
                thead {
                  th {
                    font-family: "NotoSans-SemiBold";
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.133333px;
                    background: none;

                    &.editable-column {
                      &.number-col {
                        text-align: left;
                      }
                    }
                  }
                }

                tbody {
                  @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.133333px;
                  }

                  .share-input-field {
                    .form-control {
                      background: $shadowGrey;
                      border: 1px solid $darkGreyishBlue;
                      border-radius: 4px;
                      text-align: left;
                      width: 40%;

                      @media (max-width: 768px) {
                        width: 80%;
                        line-height: 19px;
                      }
                    }
                  }

                  .delete-investment-portfolio {
                    .delete-investment-portfolio-button {
                      &.btn-primary {
                        --bs-btn-color: #0033aa;
                        --bs-btn-bg: none;
                        --bs-btn-border-color: none;
                        --bs-btn-hover-bg: none;
                        --bs-btn-hover-border-color: none;
                        --bs-btn-active-color: none;
                        --bs-btn-active-bg: none;
                        background: url(../../../src/App/assets/img/bin.png) no-repeat 0% 50%;

                        @media (max-width: 768px) {
                          width: 24px;
                          height: 20px;
                        }

                        .delete-text {
                          margin-left: 15px;

                          @media (max-width: 768px) {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .blue-block {
      border: 1px dashed $azureBlue;
      height: 74px;
      width: 100%;
      background: $lightBlueGrey;
      border-radius: 4px;
      text-align: center;
      margin: 20px 0 80px 0;
      padding-top: 25px;

      @media (max-width: 768px) {
        padding: 15px;
        height: max-content;
      }

      @media (max-width: 400px) {
        padding: 15px 5px;
      }

      &.preservation {
        height: 48px;
        padding-top: 12px;
      }

      .add-text {
        font-family: "NotoSans-Medium";
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 1.23px;
        color: $azureBlue;
        text-decoration-line: none;
        margin-left: 5px;
      }
    }

    .deductions-heading {
      font-family: "NotoSans-SemiBold";
      font-size: 16px;
      line-height: 27px;
      margin: 25px 0px 0px 25px;

      @media (max-width: 768px) {
        margin: 20px 0px 0px 15px;
        line-height: 22px;
      }
    }

    .documents-sub-heading {
      font-family: "NotoSans-Medium";
      line-height: 20px;
      margin: 20px 0px 0px 25px;

      @media (max-width: 768px) {
        margin: 20px 0px 0px 15px;
      }
    }

    .documents-text {
      font-size: 12px;
      line-height: 20px;
      margin-left: 25px;
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: auto;
      }
    }

    .file-upload-documents-header {
      font-family: "NotoSans-SemiBold";
      font-weight: 600;
      line-height: 20px;
    }

    .file-drop-area {
      background: #eef5ff;
      border-radius: 4px;
      font-size: 12px;
      margin: 10px 40px 20px 25px;
      text-align: center;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        margin-left: 0px;
        margin-right: 15px;
      }

      @media (max-width: 360px) {
        padding: 0px;
      }

      .upload-image {
        @media (max-width: 768px) {
          margin-right: 10px;
          vertical-align: top;
        }
      }

      &.neo-file-drop {
        border: 2px dashed $azureBlue;

        .file-upload-label {
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $azureBlue;
        }
      }

      .browse-button {
        &.btn {
          border: none;
          background: none;
          text-decoration: underline;
          cursor: pointer;
          color: $azureBlue;
          font-family: "NotoSans-Medium";
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.428571px;
          padding-left: 0.3%;
          width: 130px;
        }
      }
    }

    .row {
      &.file-upload-documents {
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px;
        display: inline-flex;

        @media (max-width: 768px) {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          &.phone-remove-margin {
            margin-left: 0px;
          }
        }

        .add-margin-to-right {
          @media (max-width: 768px) {
            display: flex;
            justify-content: center;

            &.phone-justify-left {
              justify-content: left;
            }
          }

          .uploaded-document-info-block {
            border-radius: 4px;
            background: #eef5ff;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            width: 220px;
            margin-left: 12px;

            @media (max-width: 768px) {
              margin-left: 0px;
              margin-top: 5px;
            }

            &.add-margin-top {
              margin-top: 5px;
            }

            .uploaded-document-info-text {
              color: $azureBlue;
              font-family: "NotoSans-Bold";
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
              letter-spacing: 0.429px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .file-name {
                max-width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }

              .image-container {
                white-space: nowrap;
                display: flex;
              }

              .uploaded-document-info-image {
                margin-left: 10px;
                cursor: pointer;

                &.delete {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
    }

    .member-declaration-text {
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.133333px;
      margin: 30px 60px 30px 60px;

      @media (max-width: 768px) {
        line-height: 24px;
        margin: 30px 15px 30px 15px;
      }

      .bold {
        font-family: "NotoSans-Bold";
        font-weight: 700;
      }
    }

    .checkbox-table-bak {
      margin-left: 49px;

      @media (max-width: 768px) {
        margin-left: 5px;
      }
    }

    .review-and-submit-box {
      background: $white;
      box-shadow: 0px 1px 4px rgba(139, 146, 167, 0.5);
      border-radius: 5px;
      min-height: 150px;
      height: auto;
      min-height: fit-content;
      padding-bottom: 20px;

      @media (max-width: 768px) {
        min-height: 200px;
        height: auto;
        margin: 25px 15px;
        padding-bottom: 5px;
      }

      .review-and-submit-box-heading {
        background: #f2f4f5;
        border-radius: 5px 5px 0px 0px;
        height: 46px;
        padding: 10px 20px 10px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 400px) {
          padding: 10px 15px 10px 15px;
        }

        .heading {
          font-family: "NotoSans-SemiBold";
          font-weight: 600;
          font-size: 18px;
          line-height: 18px;
          color: #214ebf;
        }

        .edit {
          line-height: 35px;
          letter-spacing: 0.2px;
          text-decoration-line: underline;
          color: #0033aa;
        }
      }

      .review-and-submit-box-context {
        line-height: 25px;
        letter-spacing: 0.2px;
        color: $darkerGreyishBlue;
        margin-left: 25px;
        margin-top: 20px;
        padding-right: 30px;

        @media (max-width: 768px) {
          margin-left: 0px;
          margin-top: 0px;
          padding: 20px 15px 5px 15px;
        }

        .remove-padding-to-left {
          display: flex;
          justify-content: left;

          @media (max-width: 768px) {
            justify-content: center;
          }
        }

        .bank-validation-error-message {
          height: fit-content;
          background-color: #facdcd;
          border-radius: 4px;
          padding: 5px 10px;
          margin-bottom: 5px;

          &.waiting {
            background-color: $amber;
          }

          &.valid {
            color: $white;
            background-color: $darkerGreen;
          }
        }

        .text {
          font-family: "NotoSans-SemiBold";
        }

        .note {
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.2px;
          margin-top: 10px;
        }
      }
    }

    .phone-padding-left-zero {
      @media (max-width: 768px) {
        padding-left: 0px;
      }
    }

    .withdrawal-details-input-field-left {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 20px;

      .savings-withdrawal-amount-heading {
        margin-left: 25px;

        @media (max-width: 768px) {
          margin-left: 15px;
        }
      }

      .withdrawal-details-input-field {
        margin-left: 25px;

        @media (max-width: 768px) {
          margin-left: 15px;
        }

        label {
          display: none;
        }

        .form-control {
          height: 48px;
          background: $shadowGrey;
          border: 1px solid $darkGreyishBlue;
          width: 95%;
          text-align: left;
        }
      }


    }

    .sub-text-savings-withdrawal-amount {
      display: inherit;
      margin-top: 2px;
      margin-left: 25px;

      &.annual-income-note {
        margin-left: auto;

        @media (max-width: 768px) {
          margin-left: 15px;
          margin-right: 15px;
        }
      }

      @media (max-width: 768px) {
        margin-left: 15px;
      }
    }

    .withdrawal-details-padding-on-mobile {
      @media (max-width: 768px) {
        padding-right: 20px;
      }
    }
  }
}

.modal-container {
  .modal-dialog {
    &.modal-lg {
      &.withdrawal-claim-process-status-modal {
        .modal-content {
          .modal-header {
            display: none;
          }

          .modal-body {
            .alert-img {
              margin-top: 20px;
              width: 40px;
              height: 35px;
              margin-bottom: 20px;
            }

            .checked-img {
              width: 222px;
              height: 100px;
              margin-top: 10px;
              margin-bottom: 20px;

              @media (max-width: 768px) {
                width: 184px;
                height: 83px;
                margin-top: 20px;
              }
            }

            .details {
              &.margin {
                margin-top: 20px;
              }

              .bold {
                font-weight: 600;
                font-family: "NotoSans-SemiBold";
              }
            }
          }

          .modal-footer {
            width: 91%;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}